import React from 'react';
import styled from 'styled-components';

interface DatePickerProps {
  label: string;
}

const DatePicker: React.FC<DatePickerProps> = ({ label }) => {
  return (
    <DatePickerWrapper>
      <Label htmlFor={label}>{label}</Label>
      <DateInput id={label} type="date" />
    </DatePickerWrapper>
  );
};

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
`;

const Label = styled.label`
  color: #11142d;
  font-weight: 700;
  margin-bottom: 8px;
`;

const DateInput = styled.input`
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 16px;
  font-size: 16px;
  color: #9a9ab0;
`;

export default DatePicker;