import React from 'react';
import styled from 'styled-components';
import ProgressSteps from './ProcessStep';
import FormField from './FormField';
import Dropdown from './Dropdown';


interface SDSProcessingPageProps{
  // Add any props if needed
}

const SDSProcessingPage: React.FC<SDSProcessingPageProps> = () => {
  return (
    <PageWrapper>
      <SideContent>
        <TeamImage />
        
        <DownloadMessage>
          <h1> Data Extraction</h1>
          <Accent />
          Extracting data to provide you with valuable information. Please wait!
        </DownloadMessage>
      </SideContent>
      <MainContent>

        <FormContainer>
          <ProgressSteps />
          <FormField label="Attachment" placeholder="Browse" isFileInput />
          <Dropdown label="Data Extracted" options={['Yes', 'No']} />
          <Dropdown label="Data Validation" options={['Yes', 'No']} />
        
          <SubmitButton>Next</SubmitButton>
        </FormContainer>
      </MainContent>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  background-color: #4952a3;
  padding: 10px;
  overflow: hidden;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const SideContent = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  width: 30%;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const TeamImage = styled.div`
  height: 500px;
  width: 100%;
   background-image: url('https://s3-alpha-sig.figma.com/img/5f5c/ee0b/5ab3aee8eacb017796e3d802b7c3d0c2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kOcV1pOgsk4UmeFsB6eXF2sLqPuvrzM5RWIoVbQl55dgbMS512RjauzyXg46v5oj~WeB1N3tQfgTnNQ4PqvXQR-ZkPohiHA~XOFwudkI4~xNG0vTpJwUGQj5RloTpNf8l4GOvyKfBShrc~wLDm7BJP-zTOMhp50K~3H~acAEKZTa2bJ8k8bNYbOKkUi73qW-kDXc6GauRu65Plmflkp6S6OMNcYuw3wYMJvYzf1jWxRrMry2NC71UkKiPzXg483psFj8Sr~wENnXV3vsgQsHkxnb55hSASweFOHkehZsPkX6QpvdYVwhHPbJOT9ZSOzT6wFv8ou-6qvsqlxpmoUeAg__');
  background-size: cover; /* Ensures the background covers the entire area */
  background-repeat: no-repeat; /* Prevents the background from repeating */
  background-position: center; /* Centers the background image */
  @media (max-width: 991px) {
    max-width: 50%;
    margin: 20px 0;
  }
`;

const Accent = styled.div`
  width: 50%; /* Keep the desired width */
  height: 4px;
  background-color: #F24C4C;
  margin: 20px 0; /* Use vertical margin only to center it vertically */
  margin-left: 100px; /* Reset left margin to align it to the left */
`;

const DownloadMessage = styled.div`
  font-size: 16px; /* Reduced font size */
  text-align: center; /* Center-align text */
  margin-top: -10px; /* Reduced margin */
`;

const MainContent = styled.main`
  width: 70%;
  margin-left: 20px;
  margin-right: 20px
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

const FormContainer = styled.form`
  border-radius: 8px;
  background-color: #fff;
  padding: 88px 77px;
  border: 1px solid #fff;
  @media (max-width: 991px) {
    padding: 40px 20px;
  }
`;

const FormRow = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 40px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const SubmitButton = styled.button`
  border-radius: 8px;
  background-color: #d12031;
  color: #fff;
  padding: 15px 24px;
  font: 700 16px Poppins, sans-serif;
  border: 1px solid #fff;
  cursor: pointer;
  margin-top: 39px;
`;

export default SDSProcessingPage;