import React, { useState } from 'react';
import { Text } from '@fluentui/react';
import styled from 'styled-components';

// Assume these components are defined elsewhere

interface DataValidationPage{
    
}
const DataValidationPage: React.FC<DataValidationPage> = ({}) => {
  return (
    <FormWrapper>   
      <Status>
      <StyledText 
        variant="medium" 
        styles={{ root: { color: '#d12031' } }} // Apply custom color
      >
        To review and validate the data extracted by Marie, click on Validate.
      </StyledText>
    </Status>   
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px; // Space between fields
  margin: 30px;
`;

const Status = styled.div`
  border-radius: 8px;
  background-color: #fff;
  width: 100%;
  color: #d12031;
  padding-right: 10px;
  padding: 10px; /* Added padding for better appearance */
`;

const StyledText = styled(Text)`
  letter-spacing: 0.08px;
  margin-bottom: 10px;
  font: 700 16px/1.2 'Poppins', sans-serif; /* Ensure Poppins is imported in your project */
`;

export default DataValidationPage;
