import React, { useState } from 'react';
import { Stack, PrimaryButton } from '@fluentui/react';
import styled from 'styled-components';
import IdentificationSection from './IdentificationSection';
import HazardSection from './HazardSection';
import EditUpdateSection from './EditUpdateSection';
import CompositionSection from './CompositionInformation';
import FirstAidMeasuresSection from './FirstAidMeasures';
import FireFightingMeasuresSection from './FireFightingMeasures';
import Button from '../DownloadSDS/Button';
import AccidentalReleaseMeasures from './AccidentalReleaseMeasures';
import HandlingStorage from './HandlingStorage';
import ExposureControl from './ExposureControl';
import DisposableConsiderations from './DisposableConsiderations';

interface SdsEditorProps{
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
const SdsEditor: React.FC<SdsEditorProps> = ({onClick}) => {
  const [selectedItem, setSelectedItem] = useState<string>("Identification");
  const [pageNumber, setPageNumber] = useState<number>(1);

  const handleItemClick = (item: string) => {
    setSelectedItem(item);
    const pageMapping: { [key: string]: number } = {
      "Identification": 1,
      "Hazard Identification": 1,
      "Composition/Information on Ingredients": 1,
      "First-Aid Measures": 1,
      "Fire-Fighting Measures": 2,
      "Accidental Release Measures": 3,
      "Handling and Storage": 3,
      "Exposure Controls/Personal Protection": 3,
      "Disposal Considerations": 9
    };

    setPageNumber(pageMapping[item] || 1);
  };

  const classifications = [
    "Identification", "Hazard Identification", "Composition/Information on Ingredients", 
    "First-Aid Measures", "Fire-Fighting Measures", "Accidental Release Measures", 
    "Handling and Storage", "Exposure Controls/Personal Protection", 
    "Physical and Chemical Properties", "Stability and Reactivity", 
    "Toxicological Information", "Ecological Information", "Disposal Considerations", 
    "Transport Information", "Regulatory Information", "Other Information",
    "Additional Item 1", "Additional Item 2", "Additional Item 3", "Additional Item 4"
  ];

  const renderComponent = () => {
    switch (selectedItem) {
      case 'Identification':
        return <IdentificationSection />;
      case "Hazard Identification":
        return <HazardSection />;
      case 'Composition/Information on Ingredients':
        return <CompositionSection />;
      case 'First-Aid Measures':
        return <FirstAidMeasuresSection />;
      case 'Fire-Fighting Measures':
        return <FireFightingMeasuresSection />;
      case 'Accidental Release Measures':
        return <AccidentalReleaseMeasures/>;
      case 'Handling and Storage':
       return <HandlingStorage/>;
       case 'Exposure Controls/Personal Protection':
        return <ExposureControl/>;
      case 'Disposal Considerations':
        return <DisposableConsiderations/>;
      default:
        return null;
    }
  };

  return (
    <Stack horizontal tokens={{ childrenGap: 38 }} style={{ width: "100%", height: "100vh" }}>
      <SideColumn>
        <EditUpdateSection selectedItem={selectedItem} onClick={handleItemClick} classifications={classifications} />
      </SideColumn>
      <MainColumn>
        <Container>
          <PdfViewer key={pageNumber} src={`/BR12036_SDS-US_11-1400 NOVOPERM YELLOW HR_EN.pdf#page=${pageNumber}`} />
          <ContentSection>
            {renderComponent()}
          </ContentSection>
          <Button onClick={onClick} page="edit">Save</Button>
        </Container>
      </MainColumn>
    </Stack>
  );
};

const PdfViewer = ({ src }: { src: string }) => (
  <IframeContainer>
    <iframe src={src} width="100%" height="100%" title="PDF Viewer" />
  </IframeContainer>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ContentSection = styled.div`
  max-height: calc(100vh - 30px); /* Adjusted height considering the button space */
  margin-top: 10px; /* Add space between PdfViewer and ContentSection */
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

const MainColumn = styled.div`
  flex-grow: 1;
  margin-left: 20px;
`;

const SideColumn = styled.div`
  width: 35%;
  background-color: #4952a3;
  padding: 20px;
`;

const IframeContainer = styled.div`
  width: 100%;
  height: 40%; /* Reduced height for the PDF Viewer */
  border: 1px solid #ccc;
  border-radius: 8px;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end; /* Align the button to the right */
`;

export default SdsEditor;
