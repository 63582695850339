import React from 'react';
import styled from 'styled-components';
import TickIcon from '../../assets/image/tick_icon.png';
import CrossIcon from '../../assets/image/cross_icon.png'; // Ensure you have a cross icon

interface PasswordRequirementProps {
  text: string;
  isValid: boolean; // New prop to indicate validity
}

const PasswordRequirements: React.FC<PasswordRequirementProps> = ({ text, isValid }) => {
  return (
    <RequirementItem>
      <CheckIcon loading="lazy" src={isValid ? TickIcon : CrossIcon} alt="Check icon" />
      <RequirementText isValid={isValid}>{text}</RequirementText>
    </RequirementItem>
  );
};

const RequirementItem = styled.li`
  display: flex;
  gap: 7px;
  color: #000;
  letter-spacing: 0.07px;
  font: 400 15px/1.3 Poppins, sans-serif;
  margin-bottom: 4px;
`;

const CheckIcon = styled.img`
  width: 17px;
`;

const RequirementText = styled.span<{ isValid: boolean }>`
  flex-grow: 1;
  color: ${({ isValid }) => (isValid ? 'green' : 'black')}; // Optional: change text color based on validity
`;

export default PasswordRequirements;
