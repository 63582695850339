import React, { useState } from 'react';
import styled from 'styled-components';

// Assume these components are defined elsewhere
import FormField from './FormField';
import DatePicker from './DatePicker';

interface VerifySDSPageProps{
    revisedDate: string,
    comparison: string,
    language: string,
    version: string,
    manufacturer: string

}
const VerifySDSPage: React.FC<VerifySDSPageProps> = ({revisedDate, comparison, language, version, manufacturer}) => {
  return (
    <FormWrapper>
        <FormRow>
        <DatePicker 
          label="Revised Date" 
          placeholder="" 
          value={revisedDate} 
        />
        <FormField 
          label="Comparison" 
          placeholder="" 
          value={comparison} 
        />
      </FormRow>
      <FormField 
        label="Language" 
        placeholder="Enter the details" 
        value={language}  
      />
      <FormField 
        label="Version" 
        placeholder="Enter the details" 
        value={version} 
      />
      <FormField 
        label="Manufacturer" 
        placeholder="Browser" 
        value={manufacturer} 
      />
      
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px; // Space between fields
`;

const FormRow = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  margin-top: 0px;
  justify-content: flex-end; /* Aligns items to the right */
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center; /* Centers items in column mode */
  }
`;

export default VerifySDSPage;
