import { VaultFilled } from '@fluentui/react-icons';
import React from 'react';
import styled from 'styled-components';

interface FormFieldProps {
  label: string;
  placeholder: string;
  icon?: string;
  value: string | undefined;
}

const FormField: React.FC<FormFieldProps> = ({ label, placeholder, icon, value }) => {
  return (
    <FieldWrapper>      
      <Label htmlFor={`input-${label}`}>{label}</Label>
      <InputWrapper>
        <Input
          type="text"
          id={`input-${label}`}
          placeholder={placeholder}
          aria-label={label}
          value={value}
          disabled={true}
        />
        {icon && <Icon src={icon} alt="" aria-hidden="true" />}
      </InputWrapper>
    </FieldWrapper>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  flex-direction: row;
  justify-content: start;
  min-height: 84px;
  @media (max-width: 991px) {
    margin-top: 20px;
  }
`;

const Label = styled.label`
  color: #11142d;
  font-weight: 700;
  padding-bottom: 8px;
  font-size: 14px;
  margin-right: 40px;
  margin-top: 20px; // Adjusted margin to bring the label closer to the input
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #5541d7;
  background-color: #fff;
  padding: 20px;
  height: 50px;
  width: 400px; // Increase width of the input field here
  @media (max-width: 991px) {
    padding: 12px;
    width: 100%; // Adjust width for smaller screens if needed
  }
`;

const Input = styled.input`
  flex: 1;
  border: none;
  background: transparent;
  color: #11142d;
  font-weight: 600;
  outline: none;
  padding: 8px 0; // Added padding for vertical centering
  &::placeholder {
    color: #11142d;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
`;

export default FormField;
