import React from 'react';
import styled from 'styled-components';

const SubmitButton: React.FC = () => {
  return <StyledButton type="submit">Submit</StyledButton>;
};

const StyledButton = styled.button`
  align-self: stretch;
    border-radius: 8px;
    background-color: #d12031;
    margin: 10px 0px 43px 24px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    letter-spacing: 0.08px;
    padding: 12px 24px;
    border: 1px solid #fff;
    cursor: pointer;
    margin-left: 54px;
    margin-top: 23px;
    transition: background-color 0.3s ease;
    width: 120px;
  &:hover {
    background-color: #b51b2a;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(209, 32, 49, 0.5);
  }

  @media (max-width: 991px) {
    padding: 16px 20px;
  }
`;

export default SubmitButton;