import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';
import FormField from './FormField';

interface TicketFormProps {
  requestId: string | undefined;
}

const TicketForm: React.FC<TicketFormProps> = ({ requestId }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
  });

  const uploadFile = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault(); // Prevent default form submission

    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const response = await fetch('https://your-minio-server/upload', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        console.log('File uploaded successfully:', await response.json());
      } else {
        console.error('Upload failed:', response.statusText);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
    window.history.back();
  };

  const handleBack = () => {
    window.history.back(); // Go back to the previous page
  };

  return (
    <FormWrapper>
      <FormContainer>
        <FormTitle>Import SDS Document</FormTitle>

        {/* Request ID Row */}
        <Row>
          <FormField label="Request ID" placeholder="Enter the details" value={requestId} />
        </Row>

        {/* Attach and Browse Row */}
        <Row>
          <Label>Attach the file</Label>
          <BrowseButtonWrapper {...getRootProps()}>
            <input {...getInputProps()} id="file-input" style={{ display: 'none' }} />
            <BrowseButton>Browse</BrowseButton>
          </BrowseButtonWrapper>
        </Row>

        {selectedFile ? (
          <FileName>Selected file: <FileNameHighlight>{selectedFile.name}</FileNameHighlight></FileName>
        ) : (
          <FileName>No file selected</FileName>
        )}

        {/* Button Row */}
        <ButtonRow>
          <BackButton onClick={handleBack}>Back</BackButton>
          <UploadButton onClick={uploadFile}>Upload the file</UploadButton>
        </ButtonRow>
      </FormContainer>
    </FormWrapper>
  );
};

const FormWrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 60%;
  @media (max-width: 991px) {
    width: 100%;
    padding: 20px;
  }
`;

const FormContainer = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 60px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: 100%;
  @media (max-width: 991px) {
    padding: 40px 20px;
  }
`;

const FormTitle = styled.h1`
  color: #11142d;
  font-size: 28px;
  font-weight: 700;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;  
`;

const Label = styled.label`
  font-weight: bold;
  margin-right: 20px;  
`;

const Button = styled.button`
  padding: 15px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 700;
  color: #fff;
`;

const BrowseButton = styled(Button)`
  background-color: #4952a3;
  width: 100px; // Set a specific width for the Browse button
`;

const BrowseButtonWrapper = styled.div`
  display: inline-block; // Ensure the button does not take the full width
`;

const UploadButton = styled(Button)`
  background-color: #d12031;
  margin-top: 40px;
`;

const BackButton = styled(Button)`
  background-color: #4952a3; // Same color as the Browse button
  margin-right: 20px; // Space between Back and Upload buttons
  margin-top: 40px;
`;

const ButtonRow = styled.div`
  display: flex;
  margin-top: 40px; // Space above the button row
`;

const FileName = styled.p`
  margin: 40px 0;
  font-weight: bold;
  color: #333;
`;

const FileNameHighlight = styled.span`
  color: #4952a3; // Change the color for the selected file name
  font-weight: 600; // Adjust font weight if desired
  font-size: 16px; // Adjust font size if desired
`;

export default TicketForm;
