import React, { useState } from "react";
import { TextField, PrimaryButton, IButtonStyles, IconButton } from "@fluentui/react"; // Import IButtonStyles
import ProgressSteps from "./ProgressSteps";
import DatePicker from "./DatePicker"; // Assuming you have a DatePicker component
import Dropdown from "./Dropdown"; // Assuming you have a Dropdown component
import styled from "styled-components";
import FormField from "./FormField";
import Button from "./Button";
import DownloadSDSPage from "./DownloadSDSPage";
import VerifySDSPage from "./VerifySDSPage";
import DataValidation from "../SDSProcessing/DataValidation";
import DataExtractionPage from "./DataExtractionPage";
import IntegrationPage from "./IntegrationPage";
// import { EditUpdateSection } from "../SDSEditUpdate/EditUpdateSection";
import SdsEditor from "../SDSEditUpdate/SDSEditor";
import DataValidationPage from "./DataValidationPage";

const SDSDownloadPage: React.FC = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [requestId, setRequestID] = useState<string>("REQ001");
  const [source, setSource] = useState<string>("Direct Upload");
  const [attachment, setAttachment] = useState<string>("ABCD.pdf");
  const [issueDate, setIssueDate] = useState<string>("09-12-2024");
  const [approvedSDS, setApprovedSDS] = useState<string>("Yes");
  const [revisedDate, setRevisedDate] = useState<string>("09-12-2025");
  const [comparison, setComparison] = useState<string>("Same");
  const [language, setLanguage] = useState<string>("English");
  const [version, setVersion] = useState<string>("1.0");
  const [manufacturer, setManufacturer] = useState<string>("XYZ");
  const [dataExtracted, setDataExtracted] = useState<string>("Successful");
  const [dataValidation, setDataValidation] = useState<string>("Yes");
  const [processSpeed, setProcessSpeed] = useState<string>("700");
  const [dataAccuracy, setDataAccuracy] = useState<string>("70%");
  const [qualityCheck, setQualityCheck] = useState<string>("Done");
  const [notification, setNotification] = useState<string>("Sent");
  const [openEditAndUpdate, setOpenEditAndUpdate] = useState<boolean>(false);

  const downloadSDSMessage = (
    <>
      Your SDS download is in progress. Hang tight, it'll <br />
      ready soon!
    </>
  );

  const verifySDSAndIntegrationMessage = (
    <>
      Verify your SDS to confirm all details are correct and <br/>
      ready for use!
    </>
  );

  const dataExtractionMessage = (
    <>
      Extracting data to provide you with valuable information. <br/>
      Please wait!
    </>
  )

  const dataValidationMessage = (
    <>
      Validate the data to verify the data extracted. <br/>
      Please Validate!
    </>
  )

  const handlePrev = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();    
      setCompletedSteps((prev) => [...prev, currentStep]); // Mark current step as completed
      setCurrentStep((prev) => prev - 1); // Move to the prev step
  }

  const handleNext = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log("Coming from Editor", currentStep);
    event.preventDefault(); // Prevent default form submission behavior   
    setOpenEditAndUpdate(false);    
      setCompletedSteps((prev) => [...prev, currentStep]); // Mark current step as completed
      setCurrentStep((prev) => prev + 1); // Move to the next step        
  };

  const invokeOpenEditAndUpdate = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    console.log("Icon clicked!"); // Add this line to test
    setOpenEditAndUpdate(true);
  };
  // Define custom styles for the button
  const buttonStyles: IButtonStyles = {
    root: {
      backgroundColor: "#D12031", // Custom pink background color
      color: "#ffffff", // Custom text color
      border: "none", // Remove default border
      padding: "10px 20px", // Adjust padding if necessary
      selectors: {
        ":hover": {
          backgroundColor: "#ff1493", // Darker pink shade on hover
        },
        ":active": {
          backgroundColor: "#db5b95", // Even darker pink shade on click
        },
      },
    },
  };

  return (
    console.log("Current Step", currentStep),
    console.log("Edit and update", openEditAndUpdate),
    (
      <PageWrapper openEditAndUpdate={openEditAndUpdate}>
        {openEditAndUpdate ? (
          <SdsEditor onClick={handleNext}/>
        ) : (
          <>
            <SideContent>
              {currentStep === 1 && <DownloadSDSImage />}
              {currentStep === 2 && <VerifySDSImage />}
              {(currentStep === 3 || currentStep === 4) && <DataExtractionImage />}
              {currentStep === 5 && <IntegrationImage />}
              <DownloadMessage>
               {currentStep === 1 && <h1> Download SDS</h1>}
               {currentStep === 2 && <h1> Verify SDS</h1>}
               {currentStep === 3 && <h1> Data Extraction</h1>}
               {currentStep === 4 && <h1> Data Validation</h1>}
               {currentStep === 5 && <h1> Integration</h1>}
                <Accent />
                {currentStep === 1 && downloadSDSMessage}
                {(currentStep === 2 ||  currentStep === 5) && verifySDSAndIntegrationMessage}
                {currentStep === 3 && dataExtractionMessage}
                {currentStep === 4 && dataValidationMessage}
              </DownloadMessage>
            </SideContent>
            <MainContent>
              <FormContainer>
              <ChevronContainer>
                {currentStep > 1 && (
                  <IconButton
                    iconProps={{ iconName: "ChevronLeft" }}
                    onClick={handlePrev}
                    styles={{ root: { marginRight: 10 } }}
                  />
                )}
                <div style={{ flex: 1 }} /> {/* Spacer to push right icon to the end */}
                {currentStep < 5 && (
                  <IconButton
                    iconProps={{ iconName: "ChevronRight" }}
                    onClick={handleNext}
                    styles={{ root: { marginLeft: 10 } }}
                  />
                )}
              </ChevronContainer>
                <ProgressSteps
                  currentStep={currentStep}
                  completedSteps={completedSteps}
                />
                {currentStep === 1 && (
                  <DownloadSDSPage
                    requestId={requestId}
                    source={source}
                    attachment={attachment}
                    issueDate={issueDate}
                    approvedSDS={approvedSDS}
                  />
                )}
                {currentStep === 2 && (
                  <VerifySDSPage
                    revisedDate={revisedDate}
                    comparison={comparison}
                    manufacturer={manufacturer}
                    version={version}
                    language={language}
                  />
                )}
                {currentStep === 4 && (
                  <DataValidationPage />
                )}
                {currentStep === 3 && (
                  <DataExtractionPage
                    attachment={attachment}
                    dataExtracted={dataExtracted}
                    dataValidation={dataValidation}
                    
                  />
                )}
                {currentStep === 5 && (
                  <IntegrationPage
                    processSpeed={processSpeed}
                    dataAccuracy={dataAccuracy}
                    qualityCheck={qualityCheck}
                    notification={notification}
                  />
                )} 
                {currentStep === 4 && (
                  <>
                  {/* <Button onClick={handlePrev}>Back</Button> */}
                  <Button onClick={currentStep === 4? invokeOpenEditAndUpdate : handleNext} page="progress">{currentStep === 4 ? "Validate" : "Next"}</Button>
                  </>
                )}               
              </FormContainer>
            </MainContent>
          </>
        )}
      </PageWrapper>
    )
  );
};

// Styled Components remain unchanged
// const PageWrapper = styled.div<{ openEditAndUpdate: boolean }>`
//   background-color: ${(props) => (props.openEditAndUpdate ? "none" : "#4952a3")};
//   height: 95vh; 
//   display: flex;
//   overflow: hidden;
//   padding: ${(props) => (props.openEditAndUpdate ? "0px" : "10px")};
//   box-sizing: border-box;
//   @media (max-width: 991px) {
//     flex-direction: column;
//     padding: 20px;
//   }
// `;
// const SideContent = styled.aside`
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   color: #fff;
//   text-align: center;
//   width: 40%;
//   @media (max-width: 991px) {
//     width: 100%;
//     margin-bottom: 40px;
//   }
// `;

// const MainContent = styled.main`
//   width: 60%;
//   margin-left: 10px;
//   @media (max-width: 991px) {
//     width: 100%;
//     margin-left: 0;
//   }
// `;

// const FormContainer = styled.form`
//   margin: -10px;
//   background-color: #fff;
//   padding: 40px 50px;
//   height: 95vh;
//   gap: 20px;
//   border: 1px solid #fff;
//   @media (max-width: 991px) {
//     padding: 40px 20px;
//   }
// `;
const PageWrapper = styled.div<{ openEditAndUpdate: boolean }>`
  background-color: ${(props) => (props.openEditAndUpdate ? "none" : "#4952a3")};
  height: 95vh; 
  display: flex;
  overflow: hidden;
  padding: ${(props) => (props.openEditAndUpdate ? "0px" : "10px")};
  box-sizing: border-box;
`;

const SideContent = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  width: 40%;
`;

const MainContent = styled.main`
  width: 60%;
  margin-left: 10px;
`;

const FormContainer = styled.form`
  margin: -10px;
  background-color: #fff;
  padding: 40px 50px;
  height: 95vh;
  gap: 20px;
  border: 1px solid #fff;
`;

const ChevronContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;


const DownloadMessage = styled.div`
  font-size: 16px;
  text-align: center;
  margin-top: -10px;
`;

const Accent = styled.div`
  width: 80%;
  height: 4px;
  background-color: #f24c4c;
  margin: 20px 0;
`;

const DownloadSDSImage = styled.div`
  height: 450px;
  width: 100%;
  background-image: url("https://s3-alpha-sig.figma.com/img/2142/7f22/e1b687fb3d9714524ed27bdb1cadc30c?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=AyoJzbBiCfiu4sR6-2673a2xmm1pCva7bmfIoWg2GPC04vJ0G0~knAZkuZhLn0RdbnqG6YqRPW1dmd-0j~lxSbEBbhLVV1IeZZacttEzCqDcM99HxoZBJxeZfCt7u64PaRRcnTUuQiB44GF~8kDVYSkeGz6JFVgHwo0~AZ0WOn51rv2hU8RJ5ak1b3-Ogb-wVXwAnSVRm-ey2gSxmdzWy9G9A3c6XxsJ~0Bc2GI4ZAuioaMZzy0nTfxKolFjJH7nQWfjFMWDhvTuQJ5pcwN5MKw9mEZRVGR1sZXk4WQfrCtNjjOxMIV8pSm6I9lIpkHB4CMfzTQwC9dkbAQsBLqH5g__");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    max-width: 50%;
    margin: 20px 0;
  }
`;

const VerifySDSImage = styled.div`
  height: 500px;
  width: 100%;
  background-image: url("https://s3-alpha-sig.figma.com/img/5893/2ad0/e739204ca71badb991a8196c2d28443f?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=G1Ph9-nwZpQ4epcGQ0S1vPwDZ71RBVWOnMYsggk6xa0AMdj13GEa96RIifONHlxVy3I466z9p4pWA6HINqm5soJwK7a71QoA8RRT6uZf~LtuSX190yfApESDqYn8sZICPeyoeFqoIzD4fCzbimcTM1fRaDn0Sb-9kNU06OerCca0jaQzOk9sFeWJfh-CIDjLBg7TF4cuVjJxIVZwTSAO2E9F03tICornrKt22Y3S1bVOuMDvtud7D9Po5wqnXu5W9dMnB5OyYW5MFLv796Y8doFRHzpWs~MvqhWri4kfRa~YQ7j9vqQrWZyxmTxlLCbrS6EVQpadHM3v-Tlt~DtgxQ__");
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom:20px;
  margin-bottom: 20px;
  background-position: center;
  @media (max-width: 991px) {
    max-width: 50%;
    margin: 20px 0;
  }
`;

const DataExtractionImage = styled.div`
  height: 500px;
  width: 100%;
  background-image: url("https://s3-alpha-sig.figma.com/img/5f5c/ee0b/5ab3aee8eacb017796e3d802b7c3d0c2?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=kOcV1pOgsk4UmeFsB6eXF2sLqPuvrzM5RWIoVbQl55dgbMS512RjauzyXg46v5oj~WeB1N3tQfgTnNQ4PqvXQR-ZkPohiHA~XOFwudkI4~xNG0vTpJwUGQj5RloTpNf8l4GOvyKfBShrc~wLDm7BJP-zTOMhp50K~3H~acAEKZTa2bJ8k8bNYbOKkUi73qW-kDXc6GauRu65Plmflkp6S6OMNcYuw3wYMJvYzf1jWxRrMry2NC71UkKiPzXg483psFj8Sr~wENnXV3vsgQsHkxnb55hSASweFOHkehZsPkX6QpvdYVwhHPbJOT9ZSOzT6wFv8ou-6qvsqlxpmoUeAg__");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    max-width: 50%;
    margin: 20px 0;
  }
`;
const IntegrationImage = styled.div`
  height: 500px;
  width: 100%;
  background-image: url("https://cdn.builder.io/api/v1/image/assets/TEMP/a85d2050a8038205e200cd8d51258e0d9f8cd5ce91d61ae572c572556c10a45a?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 20px;
  @media (max-width: 991px) {
    max-width: 50%;
    margin: 20px 0;
  }
`;

// const Accent = styled.div`
//   width: 80%;
//   height: 4px;
//   background-color: #f24c4c;
//   margin: 20px 0;
//   margin-left: 40px;
// `;

// const DownloadMessage = styled.div`
//   font-size: 16px;
//   text-align: center;
//   margin-top: -10px;
// `;

// const MainContent = styled.main`
//   width: 60%;
//   margin-left: 10px;
//   @media (max-width: 991px) {
//     width: 100%;
//     margin-left: 0;
//   }
// `;

// const FormContainer = styled.form`
//   margin: -10px;
//   background-color: #fff;
//   padding: 40px 50px;
//   height: 95vh;
//   gap: 20px;
//   border: 1px solid #fff;
//   @media (max-width: 991px) {
//     padding: 40px 20px;
//   }
// `;

const FormRow = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  margin-top: 0px;
  justify-content: flex-end; /* Aligns items to the right */
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center; /* Centers items in column mode */
  }
`;

// New ButtonContainer to manage button alignment
const ButtonContainer = styled.div`
  display: flex;
  width: 80%
  justify-content: flex-end; /* Aligns the button to the right */
  margin-top: 39px; /* Adjust margin if needed */
`;

export default SDSDownloadPage;
