import React, { useState } from 'react';
import styled from 'styled-components';

// Assume these components are defined elsewhere
import FormField from './FormField';
import DatePicker from './DatePicker';

interface DownloadSDSPageProps{
    requestId: string,
    source: string,
    attachment: string,
    issueDate: string,
    approvedSDS: string

}
const DownloadSDSPage: React.FC<DownloadSDSPageProps> = ({requestId, source, attachment, issueDate, approvedSDS}) => {
  return (
    <FormWrapper>
      <FormField 
        label="Request ID" 
        placeholder="Enter the details" 
        value={requestId}  
      />
      <FormField 
        label="Source" 
        placeholder="Enter the details" 
        value={source} 
      />
      <FormField 
        label="Attachment" 
        placeholder="Browse" 
        value={attachment} 
      />
      <FormRow>
        <DatePicker 
          label="Date of Issue" 
          placeholder="" 
          value={issueDate} 
        />
        <FormField 
          label="Approved SDS" 
          placeholder="" 
          value={approvedSDS} 
        />
      </FormRow>
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px; // Space between fields
`;

const FormRow = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  margin-top: 0px;
  justify-content: flex-end; /* Aligns items to the right */
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center; /* Centers items in column mode */
  }
`;

export default DownloadSDSPage;
