import React, { useState } from 'react';
import styled from 'styled-components';
import { TableBody, TableCell, TableRow, Table, TableHeader, TableHeaderCell, TableCellLayout } from "@fluentui/react-components";
import { Edit24Regular, Eye24Regular } from "@fluentui/react-icons";
import { useNavigate } from 'react-router-dom';

interface Item {
  requestId: { label: string };
  action: { label: string };
  IssueDate: { label: string };
  NextReviewDate: { label: string };
  document: { label: string };
  source: { label: string };
  Version: { label: string };
  productName: { label: string };
  region: { label: string };
  status: { label: string };
}

interface DocumentTableProps {
  filters: {
    name: string;
    status: string;
    startDate: string;
    endDate: string;
  };
}

const documentData: Item[] = [
  {
    requestId: { label: '1314a92c-01f3-49cb-b829-dcf7f950aef3' },
    document: { label: 'BR12036_SDS-US_11-1400 NOVOPERM YELLOW HR_EN.pdf' },
    IssueDate: { label: '2023-09-25' },
    NextReviewDate: { label: '2024-09-25' },
    productName: { label: 'Novoperm Yellow HR' },
    source: { label: 'Direct Upload' },
    Version: { label: '1.0' },
    status: { label: 'Completed' },
    region: { label: 'US' },
    action: { label: 'view' },
  },
  {
    requestId: { label: '0b9e4b1a-4d37-4d21-bb4b-1c1c5aeb3ed0' },
    document: { label: 'BR8047_SDS_AOS IRGAFOS 126 PHO 25KG_EN.pdf' },
    IssueDate: { label: '2023-09-25' },
    NextReviewDate: { label: '2024-09-25' },
    productName: { label: 'Irgafos' },
    source: { label: 'Direct Upload' },
    Version: { label: '1.0' },
    status: { label: 'Completed' },
    region: { label: 'US' },
    action: { label: 'view' },
  },
  {
    requestId: { label: 'a7c8e85c-6183-4b45-b9ab-9df5e60f8990' },
    document: { label: 'BR8112_SDS-EU_UV TINUVIN 234 PWD BG BASF_EN 1.pdf' },
    IssueDate: { label: '2023-08-15' },
    NextReviewDate: { label: '2024-08-15' },
    productName: { label: 'Tinuvin' },
    source: { label: 'API Upload' },
    Version: { label: '2.0' },
    status: { label: 'In progress' },
    region: { label: 'EU' },
    action: { label: 'view' },
  },
  {
    requestId: { label: 'f1a0e8a1-9cbb-469f-b6bc-1f1f4b3912b6' },
    document: { label: 'Acetone 2' },
    IssueDate: { label: '2023-08-15' },
    NextReviewDate: { label: '2024-08-15' },
    productName: { label: 'Acetone' },
    source: { label: 'API Upload' },
    Version: { label: '2.0' },
    status: { label: 'Pending' },
    region: { label: 'EU' },
    action: { label: 'view' },
  },
  {
    requestId: { label: 'b39e3f0e-48c0-4b09-a3b4-31c66b80a9d4' },
    document: { label: 'BR7481_SDS_Zinc Stearate SW Veg Faci_EN' },
    IssueDate: { label: '2023-08-15' },
    NextReviewDate: { label: '2024-08-15' },
    productName: { label: 'Calcium Stereate' },
    source: { label: 'API Upload' },
    Version: { label: '2.0' },
    status: { label: 'Not yet Started' },
    region: { label: 'EU' },
    action: { label: 'view' },
  },
  {
    requestId: { label: 'e1b6a5dc-3e22-44b8-9f1d-87e34df5f890' },
    document: { label: 'BR23058_SDS-US_12-2200 PIGMENT RED 57_EN.pdf' },
    IssueDate: { label: '2023-09-26' },
    NextReviewDate: { label: '2024-09-26' },
    productName: { label: 'Pigment Red 57' },
    source: { label: 'Direct Upload' },
    Version: { label: '1.1' },
    status: { label: 'Completed' },
    region: { label: 'US' },
    action: { label: 'view' },
  },
  {
    requestId: { label: 'c0a4e4bb-123e-4b55-8f6a-2f13d94c6a0d' },
    document: { label: 'BR3456_SDS_AOS UV LITEX 25KG_EN.pdf' },
    IssueDate: { label: '2023-09-27' },
    NextReviewDate: { label: '2024-09-27' },
    productName: { label: 'LITEX' },
    source: { label: 'Direct Upload' },
    Version: { label: '1.0' },
    status: { label: 'Pending' },
    region: { label: 'US' },
    action: { label: 'view' },
  },
  {
    requestId: { label: 'df7e23e1-64b5-42f5-8e45-6f1d79c5b85d' },
    document: { label: 'BR9812_SDS-EU_AQUA LUMEN 50ML_EN.pdf' },
    IssueDate: { label: '2023-08-20' },
    NextReviewDate: { label: '2024-08-20' },
    productName: { label: 'Aqua Lumen' },
    source: { label: 'API Upload' },
    Version: { label: '2.1' },
    status: { label: 'In progress' },
    region: { label: 'EU' },
    action: { label: 'view' },
  }
  // More items...
];

const columns = [
  { columnKey: "requestId", label: "Request ID" },
  { columnKey: "document", label: "Document Name" },
  { columnKey: "IssueDate", label: "Issue Date" },
  { columnKey: "NextReviewDate", label: "Next Review Date" },
  { columnKey: "productName", label: "Product Name" },
  { columnKey: "source", label: "Source" },
  { columnKey: "Version", label: "Version No" },
  { columnKey: "status", label: "Status" },
  { columnKey: "region", label: "Region" },
  { columnKey: "action", label: "Action" },
] as const;

type ColumnKey = (typeof columns)[number]["columnKey"];

const TableDocument: React.FC<DocumentTableProps> = ({filters}) => {
  const [sortConfig, setSortConfig] = useState<{ key: ColumnKey | null, direction: 'ascending' | 'descending' | null }>({
    key: null,
    direction: null,
  });
  //const dispatch = useAppDispatch();
    //const [getAllSDSRequest,getAllSDSRequestResponse] = useFetchAllSDSRequestsMutation();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [items, setItems] = useState<Item[]>(documentData);
    const [searchText, setSearchText] = useState<string>('');
    const [statusFilter, setStatusFilter] = useState<string | number>(''); 
    const [sortedColumn, setSortedColumn] = useState<{ key: keyof Item; isSortedDescending: boolean }>({
        key: 'requestId',
        isSortedDescending: false,
    });
    const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toDate, setToDate] = useState<Date | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage] = useState<number>(5); 
    const [documentList, setDocumentList] = useState<Document>();
    const [openDocView, setOpenDocView] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
  const navigate = useNavigate();

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key as ColumnKey].label;
        const bValue = b[sortConfig.key as ColumnKey].label;

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: ColumnKey) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleEdit = (requestId: string) => {
    navigate(`/sdsrequest/import/${requestId}`);
    // Add your edit logic here (e.g., navigate to an edit page)
  };

  const handleView = (requestId: string) => {
    window.open("http://localhost:3000/sdsrequest/download", "_blank");   
  };

  const filteredItems = React.useMemo(() => {
    return documentData.filter(item => {
      const matchesName = item.document.label.toLowerCase().includes(filters.name.toLowerCase());
      const matchesStatus = filters.status === 'All' || item.status.label === filters.status;

      const issueDate = new Date(item.IssueDate.label);
      const matchesDateRange =
        (!filters.startDate || issueDate >= new Date(filters.startDate)) &&
        (!filters.endDate || issueDate <= new Date(filters.endDate));

      return matchesName && matchesStatus && matchesDateRange;
    });
  }, [filters]);  

  return (
    <TableWrapper>
      <Table aria-label="Styled table" style={{ minWidth: "510px" }}>
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell key={column.columnKey} onClick={() => requestSort(column.columnKey)}>
                {column.label}
                {sortConfig.key === column.columnKey && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredItems.map((item) => (
            <TableRow key={item.requestId.label}>
              {columns.map((column) => (
                <TableCell key={column.columnKey}>
                  <TableCellLayout>
                    {column.columnKey === 'document' ? (
                      <a href={process.env.PUBLIC_URL + item[column.columnKey as ColumnKey].label} target="_blank" rel="noopener noreferrer">
                        {item[column.columnKey as ColumnKey].label}
                      </a>
                    ) : column.columnKey === 'action' ? (
                      <EyeIconWrapper>
                        {item.status.label === 'Pending' ? (
                          <Edit24Regular aria-label="Edit Document" onClick={() => handleEdit(item.requestId.label)} />
                        ) : (
                          <Eye24Regular aria-label="View Document" onClick={() => handleView(item.requestId.label)} />
                        )}
                      </EyeIconWrapper>
                    ) : column.columnKey === 'status' ? (
                      <StatusLabel status={item.status.label}>
                        {item.status.label}
                      </StatusLabel>
                    ) : (
                      item[column.columnKey as ColumnKey].label
                    )}
                  </TableCellLayout>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  tr {
    border-bottom: none;
  }

  th {
    padding: 5px 8px;
    background-color: #4952a3;
    color: white;
    cursor: pointer;
    text-align: center;
  }

  td {
    padding: 8px 15px;
    text-align: center;
    white-space: nowrap; /* Prevent line breaks */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  }

  th:first-child, td:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  th:last-child, td:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  tbody tr:nth-child(even) {
    background-color: #EAE7E7;
  }
`;

const EyeIconWrapper = styled.span`
  cursor: pointer;
  color: #4952a3;
  &:hover {
    color: #333;
  }
`;

const StatusLabel = styled.span<{ status: string }>`
  color: ${({ status }) => {
    if (status === 'Completed') return 'green';
    if (status === 'Not yet Started') return 'gray';
    if (status === 'Pending') return 'red'; 
    if (status === "In progress") return 'orange';
    return 'black'; 
  }};
`;

export default TableDocument;
