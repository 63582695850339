import React, { useState } from 'react';
import styled from 'styled-components';

const RatingSection: React.FC = () => {
  const [rating, setRating] = useState<number>(0); // State to keep track of the current rating

  // Function to handle star clicks
  const handleStarClick = (index: number) => {
    setRating(index + 1); // Set the rating based on clicked star index (0-based)
  };

  return (
    <RatingContainer>
      <RatingTitle>Overall Rating</RatingTitle>
      <StarRatingBox>
        <StarRating>
          {/* Map through 5 stars and determine if each is filled or empty based on the rating */}
          {Array.from({ length: 5 }, (_, index) => (
            <Star
              key={index}
              onClick={() => handleStarClick(index)}
              isFilled={index < rating} // Determine if the star should be filled
            //   onMouseEnter={() => setRating(index + 1)} // Set rating on hover
            // onMouseLeave={() => setRating(rating)} // Reset rating on mouse leave
            >
              {index < rating ? '★' : '☆'} {/* Use filled star or empty star */}
            </Star>
          ))}
        </StarRating>
      </StarRatingBox>
      <RatingInstruction>Click to rate</RatingInstruction>
    </RatingContainer>
  );
};

const RatingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 55px;
  margin-top: 29px;
`;

const RatingTitle = styled.h2`
  font-size: 28px;
      margin-top: -3px;
`;

const StarRatingBox = styled.div`
  display: flex;
  gap: 10px; // Space between stars
`;

const StarRating = styled.div`
  display: flex;
  gap: 6px;
`;

const Star = styled.div<{ isFilled: boolean }>`
  border: 2px solid ${props => (props.isFilled ? '#4952A3' : '#e6e6e6')}; // Border color matches the star color
  border-radius: 2px; // Rounded corners for the box
  background-color: white;
  padding: 0px 6px; // Padding inside the box
  display: flex;
  margin-top: 12px;
  justify-content: center; // Center icon horizontally
  align-items: center; // Center icon vertically
  cursor: pointer; // Pointer cursor for clickable stars
  transition: transform 0.3s ease; // Transition for scale on hover
  font-size: 40px; // Increased star size
  color: ${props => (props.isFilled ? '#4952A3' : '#e6e6e6')}; // Change filled star color to blue, empty star remains light gray

  &:hover {
    transform: scale(1.1); // Scale up on hover
  }
`;

const RatingInstruction = styled.p`
  color: #4b4b4b;
  font-size: 12px;
  font-weight: 400;
  margin-top: 6px;
`;

export default RatingSection;
