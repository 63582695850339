import React, { useState } from 'react';
import styled from 'styled-components';
import RatingSection from './RatingSection';
import RatingIllustration from './RatingIllustration';  
import Sidebar from '../sidebar-ui/Sidebar';
import ReviewForm from './ReviewForm';
import TermsAndConditions from './TermsAndConditions';
import SubmitButton from './SubmitButton';

const ContactForm: React.FC = () => {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState<boolean>(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const sidebarStyle = {
    width: isSidebarCollapsed ? '5%' : '21%',
    transition: 'width 0.3s ease',
  };

  const contentStyle = {
    width: isSidebarCollapsed ? '95%' : '79%',
    marginLeft: isSidebarCollapsed ? '60px' : '65px',
    transition: 'width 0.3s ease, margin-left 0.3s ease',
  };

  return (
    <ContactFormWrapper>
      <MainContent>
        <Logo src="https://cdn.builder.io/api/v1/image/assets/TEMP/4bc7ce955090e3b2eb5691e3b35ae6c7c32269be3e787b2f90c95c44fe519e86?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" alt="Company Logo" />
        <FormContainer>
          <RatingSection />
          <ReviewForm />
          <TermsAndConditions setIsTermsAccepted={setIsTermsAccepted} />
          <SubmitButton />
        </FormContainer>
      </MainContent>
      <RatingIllustration />
    </ContactFormWrapper>
  );
};

const ContactFormWrapper = styled.main`
  background-color: #fff;
  display: flex;
  padding-left: 16px;
  gap: 12px;
  flex-wrap: wrap;
`;

const MainContent = styled.section`
  display: flex;
  flex-direction: column;
  color: #292929;
  font: 700 14px Poppins, sans-serif;
  width: 65%;
  margin-left: -35px;
  margin-right: -45px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Logo = styled.img`
  aspect-ratio: 0.84;
  object-fit: contain;
  width: 32px;
  align-self: center;
  margin-left: 75px;
`;

const FormContainer = styled.form`
  border-radius: 32px;
  background-color: #f4f4f4;
  display: flex;
  width: 98%;
  flex-direction: column;
  height: 92vh;
      margin-top: -21px;
    margin-left: 16px;

  @media (max-width: 991px) {
    max-width: 100%;
    padding: 100px 20px 0;
  }
`;

export default ContactForm;
