import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import { Provider } from "react-redux";
import { store } from "./store"
import { mergeStyles } from '@fluentui/react';
import reportWebVitals from './reportWebVitals';
import LoginPage from './pages/login-ui/LoginPage';
import Sidebar from './pages/sidebar-ui/Sidebar';
import HelpCenter from './pages/contactus-ui/HelpCenter';
import { ForgotPassword } from './pages/authentication/ForgotPassword';
import ForgotPasswordPage from './pages/forgotPassword-ui/ForgotPasswordPage';
 
import SdsDownloadPage from './pages/sdsRequests/DownloadSDS/SDSDownloadPage';
import VerifySDSPage from './pages/sdsRequests/VerifySDS/VerifySDSPage';
import SDSProcessPage from './pages/sdsRequests/SDSProcessing/SDSProcessingPage';
import IntegrationProcess from './pages/sdsRequests/IntegrationProcess/IntegrationProcess';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import ResetPassword from './pages/resetPassword-ui/ResetPassword';
import SettingsPage from './pages/settings-ui/SettingsPage';


import TicketSubmissionPage from './pages/ticketSubmission-ui/TicketSubmissionPage';
import SdsEditor from './pages/sdsRequests/SDSEditUpdate/SDSEditor';

import UserManagementPage from './pages/userManagement-ui/UserManagementPage';
import ContactForm from './pages/feedback-ui/ContactForm';


mergeStyles({
  ':global(#root)': {
    margin: '0px 0px 0px 0px',
    padding: 0,
    height: '100vh',
  },
});
 
const contactStyle = {
  margin: "0px 0px 0px 16px"
}
 

async function main() {
 
  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    <Provider store={store}>
        <App />
      {/* <SdsEditor/> */}
       {/* <LoginPage/> */}
       {/* <ForgotPasswordPage/> */}
      {/* <ResetPassword/> */}
      {/* <SdsDownloadPage/> */}
      {/* <VerifySDSPage/> */}
      {/* <SDSProcessPage/> */}
      {/* <IntegrationProcess/> */}
      {/* <Sidebar/> */}
      {/* <Sidebar/> */}
        {/* <SettingsPage/> */}
       {/* <ForgotPasswordPage/> */}
      {/* <ResetPassword/> */}       
       {/* <Sidebar/> */}
        {/* <HelpCenter/>   */}
        {/* <ContactForm/> */}
       {/* <UserManagementPage/> */}
        {/* <TicketSubmissionPage/> */}
         {/* <SdsEditor/> */}
        
     
    </Provider>
  );
}
 
main();

reportWebVitals();
 
initializeIcons();
function ContactStyle(arg0: { ':global(body,html,#root)': { margin: number; padding: number; height: string; }; }): any {
  throw new Error('Function not implemented.');
}
 
 

 