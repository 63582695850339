import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Eye, EyeOff } from 'react-feather'; // Import eye icons from react-feather

interface PasswordInputProps {
  label: string;
  placeholder: string;
  required?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  showPassword: boolean; // New prop for showing/hiding password
  toggleShowPassword: () => void; // New prop to toggle password visibility
}

const PasswordInput: React.FC<PasswordInputProps> = ({
  label,
  placeholder,
  required = false,
  onChange,
  showPassword,
  toggleShowPassword,
}) => {
  return (
    <InputWrapper>
      <InputLabel htmlFor={label.toLowerCase().replace(' ', '-')}>
        {label}
        {required && <RequiredAsterisk>*</RequiredAsterisk>}
      </InputLabel>
      <InputContainer>
        <StyledInput
          type={showPassword ? 'text' : 'password'} // Show/hide based on the prop
          id={label.toLowerCase().replace(' ', '-')}
          placeholder={placeholder}
          required={required}
          onChange={onChange}
        />
        <TogglePasswordButton type="button" onClick={toggleShowPassword}>
          {showPassword ? <EyeOff size={20} /> : <Eye size={20} />} {/* Toggle icon */}
        </TogglePasswordButton>
      </InputContainer>
    </InputWrapper>
  );
};

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
      margin-top: -14px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const InputLabel = styled.label`
  font-family: Poppins, sans-serif;
  color: #420610;
  font-weight: 700;
  margin-left: 10px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const RequiredAsterisk = styled.span`
  color: #ff0004;
`;

const InputContainer = styled.div`
  position: relative; /* To position the toggle button */
`;

const StyledInput = styled.input`
  border-radius: 8px;
  border: 1px solid #5541d7;
  background-color: #fff;
  margin-top: -11px;
  font-family: Mulish, sans-serif;
  color: #9a9ab0;
  font-weight: 400;
  padding: 18px 24px;
  width: 560px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 18px 20px;
  }
`;

const TogglePasswordButton = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #9a9ab0; // Adjust color as needed
`;

export default PasswordInput;
