import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import ForgotPasswordForm from './ForgotPasswordForm';
import IllustrationSection from './IllustrationSection';
 
const ForgotPasswordPage: React.FC = () => {
  return (
    <StyledPage>
      <ContentWrapper>
        <LeftColumn>
          <Header />
          <ForgotPasswordForm />
        </LeftColumn>
        <RightColumn>
          <IllustrationSection />
        </RightColumn>
      </ContentWrapper>
    </StyledPage>
  );
};
 
const StyledPage = styled.main`
  overflow: hidden;
`;
 
const ContentWrapper = styled.div`
  display: flex;
  // gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    // gap: 0;
  }
`;
 
const LeftColumn = styled.section`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 60%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
 
const RightColumn = styled.section`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 40%;
  @media (max-width: 991px) {
    width: 100%;
  }
`;
 
export default ForgotPasswordPage;