import React from 'react';
import styled from 'styled-components';

interface HeaderProps {
  activeTab: 'active' | 'archived'; 
  setActiveTab: (tab: 'active' | 'archived') => void; 
}

const Header: React.FC<HeaderProps> = ({ activeTab, setActiveTab }) => {
  return (
    <StyledHeader>
      <TabGroup>
        <Tab 
          active={activeTab === 'active'} 
          onClick={() => setActiveTab('active')}
          aria-pressed={activeTab === 'active'}
          position="left" 
        >
          Active List
        </Tab>
        <Tab 
          active={activeTab === 'archived'} 
          onClick={() => setActiveTab('archived')}
          aria-pressed={activeTab === 'archived'}
          position="right" // To style the right-side button
        >
          Archived List
        </Tab>
      </TabGroup>
      <Title>{activeTab === 'archived' ? 'Archived List' : 'SDS Document List'}</Title>
      <FilterIcon 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/7b78fc619f245a482664724a567b824068a36d6e8b5d437f67fe2a85a10e1265?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" 
        alt="Filter" 
      />
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  display: flex;
  width: 100%; 
  margin: auto; 
  align-items: center; 
  padding: 17px;
`;

const TabGroup = styled.div`
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #dcdbdd;
  overflow: hidden;
  width:35%;
`;

const Tab = styled.button<{ active?: boolean; position: 'left' | 'right' }>`
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 400;
  background-color: ${props => props.active ? '#4952a3' : '#fff'};
  color: ${props => props.active ? '#fff' : '#4952a3'};
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
  flex: 1;
  text-align: center;

  &:first-child {
    border-right: 1px solid #dcdbdd;
  }

  &:hover {
    background-color: ${props => props.active ? '#4952a3' : '#f0f0f0'};
    color: ${props => props.active ? '#fff' : '#4952a3'};
  }
  border-top-left-radius: ${props => props.position === 'left' ? '12px' : '0'};
  border-bottom-left-radius: ${props => props.position === 'left' ? '12px' : '0'};
  border-top-right-radius: ${props => props.position === 'right' ? '12px' : '0'};
  border-bottom-right-radius: ${props => props.position === 'right' ? '12px' : '0'};

  @media (max-width: 991px) {
    padding: 8px 16px;
  }
`;

const FilterIcon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 39px;
  align-self: flex-start; 
`;

const Title = styled.h1`
  color: #010101;
  font-size: 34px;
  font-weight: 600;
  text-align: right;
  margin: 0;
  flex: 1; 
`;

export default Header;
