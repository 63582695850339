import React from 'react';
import styled from 'styled-components';

const WelcomeSection: React.FC = () => {
  return (
    <WelcomeWrapper>
      <WelcomeContent>
        <WelcomeHeading>
          Where remote teams <LineBreak /> get work done
        </WelcomeHeading>
        <Underline />
        <WelcomeText>
          Every login opens the door to new possibilities. <br />
          Make the most of today! <br />
        </WelcomeText>
      </WelcomeContent>
      <WelcomeImage 
        loading="lazy" 
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/6d433bba346a9d1e100b23101dede7ac64e0614c685dfe3692c16693cf6fdcc9?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" 
        alt="Remote work illustration" 
      />
    </WelcomeWrapper>
  );
};

const WelcomeWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #4952A3; 
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  text-align: center;
`;

const WelcomeContent = styled.div`
  margin-bottom: 30px;
`;

const WelcomeHeading = styled.h1`
  font-size: 40px;
  font-weight: 500;
  margin: 0; /* Remove default margin */
  padding: 15px;

  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const LineBreak = styled.span`
  display: block; /* Ensures it behaves like a block element */
  margin: 25px 0; /* Adjust this value to create desired space */
`;

const Underline = styled.div`
  background-color: #f2c94c;
  width: 160px;
  height: 4px;
  margin: 20px auto;
`;

const WelcomeText = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin: 33px 0 0; /* Add margin-top for spacing above text */
`;

const WelcomeImage = styled.img`
  width: 350px;
  height: auto;
  border-radius: 10px; /* Adjust for a subtle roundness */

  @media (max-width: 991px) {
    width: 100%;
    max-width: 300px;
  }
`;

export default WelcomeSection;
