import React from 'react';
import styled from 'styled-components';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void; // Callback to handle acceptance
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onAccept }) => {
  if (!isOpen) return null;

  return (
    <Overlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>×</CloseButton>
        <h2>Terms and Conditions</h2>
        <TermsText>
          Here are the terms and conditions for using our service...
          {/* You can add your actual terms and conditions text here */}
        </TermsText>
        <AcceptButton onClick={() => {
          onAccept(); // Call the onAccept function
          onClose(); // Close the modal
        }}>
          Accept
        </AcceptButton>
      </ModalContainer>
    </Overlay>
  );
};

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const TermsText = styled.p`
  margin: 20px 0;
`;

const AcceptButton = styled.button`
  padding: 10px 20px;
  background-color: #d12031;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #b51b2a;
  }
`;

export default Modal;
