import React from 'react';
import styled from 'styled-components';
import { Icon } from '@fluentui/react';

interface SidebarItemProps {
  icon: string;
  text: string;
  active?: boolean;
  collapsed?: boolean;
}

const ItemWrapper = styled.div<{ active?: boolean; collapsed?: boolean }>`
  display: flex;
  align-items: center;
  gap: ${props => (props.collapsed ? '0' : '8px')};
  padding: ${props => (props.collapsed ? '11px 0px' : '11px 0px')};
  justify-content: ${props => (props.collapsed ? 'flex-start' : 'flex-start')};
  transition: gap 0.3s ease, justify-content 0.3s ease, all 0.3s ease;
  &:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
`;

const IconWrapper = styled.div<{ active?: boolean }>`
  border-radius: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: ${props => (props.active ? '#E0E0E0' : '')};
  border: ${props => (props.active ? '3px solid transparent' : 'none')};
  background-clip: padding-box;
  background-image: ${props => (props.active ? 'linear-gradient(black, black), linear-gradient(135deg, #ff0000, #0000ff, #000000)' : 'none')};
  background-origin: border-box;
  background-clip: content-box, border-box;
  transition: background 0.3s ease, border 0.3s ease;
`;

const CustomIcon = styled(Icon)<{ active?: boolean }>`
  color: ${props => (props.active ? '#FFFFFF' : '#333')};
  font-size: 24px;
  transition: color 0.3s ease;
`;

const Text = styled.span<{ active?: boolean; collapsed?: boolean }>`
  font-size: 14px;
  font-weight: ${props => (props.active ? 500 : 400)};
  letter-spacing: 1px;
  color: ${props => (props.active ? '#000' : '#888')};
  display: ${props => (props.collapsed ? 'none' : 'inline')};
  transition: all 0.3s ease;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
`;


const SidebarItem: React.FC<SidebarItemProps> = ({ icon, text, active, collapsed }) => {
  return (
    <ItemWrapper active={active} collapsed={collapsed}>
      <IconWrapper active={active}>
        <CustomIcon iconName={icon} active={active} />
      </IconWrapper>
      <Text active={active} collapsed={collapsed}>{text}</Text>
    </ItemWrapper>
  );
};

export default SidebarItem;
