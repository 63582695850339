import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { AuthData, User } from "../types";

export interface UserState {
  isAuthenticated: boolean;
  data: User | undefined;
  authData: AuthData | undefined;
}

const initialState: UserState = {
  isAuthenticated: false,
  data: undefined,
  authData: undefined
};
  
  export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
      setIsAuthenticated: (state, action) => {
        state.isAuthenticated = action.payload;
      },
      setUserData: (state, action) => {
        state.data = action.payload;
      },
      setAuthData: (state, action) => {
        state.authData = action.payload;
      },
      resetUserData: (state) => {
        Object.assign(state, initialState);
      },
    },
  });
  
export const { setIsAuthenticated, resetUserData, setUserData, setAuthData } = userSlice.actions;

export const isAuthenticated = (state: RootState) => state.user.isAuthenticated;
export const getAccessToken = (state: RootState) => state.user.authData?.access_token;
   
export default userSlice.reducer;