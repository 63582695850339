import React, { useState } from 'react';
import styled from 'styled-components';

interface FormFieldProps {
  label: string;
  placeholder: string;
  value: string
  isFileInput?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({ label, placeholder, value, isFileInput = false }) => {
  
  return (
    <FieldWrapper>
      <Label htmlFor={label}>{label}</Label>
      {isFileInput ? (
        <FileInput id={label} placeholder={placeholder} />
      ) : (
        <Input id={label} placeholder={placeholder} value={value} />
      )}
    </FieldWrapper>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
`;

const Label = styled.label`
  color: #11142d;
  font-weight: 600;
  margin-bottom: 8px;
  font-size: 16px; // Use font-size instead of font
  line-height: 1.2; // Use line-height separately
  font-family: 'Poppins', sans-serif; // Specify the font-family explicitly
`;

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #5541d7;
  background-color: #fff;
  padding: 14px 22px;
  font-size: 16px; // Use font-size instead of font
  font-weight: 600; // Use font-weight separately
  line-height: 1.5; // Adjust line-height if necessary
  font-family: 'Poppins', sans-serif; // Specify the font-family explicitly
  color: #7a7a8a;
  // Disable pointer events on hover
  &:hover {
    cursor: not-allowed; // Show not-allowed cursor
  }

  // Optional: To prevent cursor change when input is disabled
  &:disabled {
    cursor: not-allowed; // Ensures disabled state shows not-allowed cursor
    background-color: #f0f0f0; // Change background to indicate it's disabled
  }
`;

const FileInput = styled(Input)`
  background-color: #ededed;
`;

export default FormField;
