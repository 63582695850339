import React, { useState } from 'react';
import styled from 'styled-components';
import { TableBody, TableCell, TableRow, Table, TableHeader, TableHeaderCell, TableCellLayout } from "@fluentui/react-components";
import { Edit24Regular, Eye24Regular } from "@fluentui/react-icons";
import { useNavigate } from 'react-router-dom';

interface Item {
  requestId: { label: string };
  action: { label: string };
  IssueDate: { label: string };
  NextReviewDate: { label: string };
  document: { label: string };
  source: { label: string };
  Version: { label: string };
  productName: { label: string };
  region: { label: string };
  status: { label: string };
}

interface DocumentTableProps {
  filters: {
    name: string;
    status: string;
    startDate: string;
    endDate: string;
  };
}

const documentData: Item[] = [
  {
    requestId: { label: 'e3d6c1ab-2f7d-4f8f-8124-3b6b6c84b2a4' },
    document: { label: 'BR20453_SDS-US_12-2000 RED DYE_EN.pdf' },
    IssueDate: { label: '2023-08-15' },
    NextReviewDate: { label: '2023-09-20' },
    productName: { label: 'Red Dye' },
    source: { label: 'Direct Upload' },
    Version: { label: '1.0' },
    status: { label: 'Completed' },
    region: { label: 'US' },
    action: { label: 'view' },
  },
  {
    requestId: { label: 'c2924a6b-5df3-4d0d-a5a2-49b6a2f53f36' },
    document: { label: 'BR67458_SDS-EU_10-3000 BLUE PIGMENT_EN.pdf' },
    IssueDate: { label: '2023-07-10' },
    NextReviewDate: { label: '2023-08-30' },
    productName: { label: 'Blue Pigment' },
    source: { label: 'API Upload' },
    Version: { label: '1.0' },
    status: { label: 'In Progress' },
    region: { label: 'EU' },
    action: { label: 'view' },
  },
  {
    requestId: { label: 'c3b24856-44f9-45a2-a32d-203ba37668f1' },
    document: { label: 'BR84672_SDS-US_14-5000 GREEN DYE_EN.pdf' },
    IssueDate: { label: '2023-06-20' },
    NextReviewDate: { label: '2023-08-01' },
    productName: { label: 'Green Dye' },
    source: { label: 'Direct Upload' },
    Version: { label: '2.0' },
    status: { label: 'Completed' },
    region: { label: 'US' },
    action: { label: 'view' },
  },
  {
    requestId: { label: 'fa94f015-3d96-4f07-b4f1-3c0a6596d9ec' },
    document: { label: 'BR73212_SDS-EU_18-7000 YELLOW PIGMENT_EN.pdf' },
    IssueDate: { label: '2023-05-05' },
    NextReviewDate: { label: '2023-07-15' },
    productName: { label: 'Yellow Pigment' },
    source: { label: 'API Upload' },
    Version: { label: '2.0' },
    status: { label: 'Pending' },
    region: { label: 'EU' },
    action: { label: 'view' },
  },
  {
    requestId: { label: '88e6f57a-1855-4895-85b1-fc301c9ae20c' },
    document: { label: 'BR12456_SDS-US_09-4500 BLACK DYE_EN.pdf' },
    IssueDate: { label: '2023-09-10' },
    NextReviewDate: { label: '2023-09-15' },
    productName: { label: 'Black Dye' },
    source: { label: 'Direct Upload' },
    Version: { label: '1.0' },
    status: { label: 'Completed' },
    region: { label: 'US' },
    action: { label: 'view' },
  },
  {
    requestId: { label: '9bd8cb71-05e2-4e36-bd83-5eb6af32c617' },
    document: { label: 'BR47280_SDS-EU_17-8000 ORANGE PIGMENT_EN.pdf' },
    IssueDate: { label: '2023-08-01' },
    NextReviewDate: { label: '2023-09-10' },
    productName: { label: 'Orange Pigment' },
    source: { label: 'API Upload' },
    Version: { label: '1.0' },
    status: { label: 'Not Yet Started' },
    region: { label: 'EU' },
    action: { label: 'view' },
  },
  {
    requestId: { label: 'f1b89a29-e5c5-4c7e-9b49-5f2de3eddb3e' },
    document: { label: 'BR38472_SDS-US_13-6000 PURPLE DYE_EN.pdf' },
    IssueDate: { label: '2023-09-01' },
    NextReviewDate: { label: '2023-09-05' },
    productName: { label: 'Purple Dye' },
    source: { label: 'Direct Upload' },
    Version: { label: '1.0' },
    status: { label: 'Completed' },
    region: { label: 'US' },
    action: { label: 'view' },
  },
  {
    requestId: { label: '2db40321-83b5-4e84-b79e-9bb8c8dc90e2' },
    document: { label: 'BR25748_SDS-EU_15-3000 GRAY DYE_EN.pdf' },
    IssueDate: { label: '2023-08-20' },
    NextReviewDate: { label: '2023-09-10' },
    productName: { label: 'Gray Dye' },
    source: { label: 'API Upload' },
    Version: { label: '2.0' },
    status: { label: 'In Progress' },
    region: { label: 'EU' },
    action: { label: 'view' },
  }
]
;

const columns = [
  { columnKey: "requestId", label: "Request ID" },
  { columnKey: "document", label: "Document Name" },
  { columnKey: "IssueDate", label: "Issue Date" },
  { columnKey: "NextReviewDate", label: "Next Review Date" },
  { columnKey: "productName", label: "Product Name" },
  { columnKey: "source", label: "Source" },
  { columnKey: "Version", label: "Version No" },
  { columnKey: "status", label: "Status" },
  { columnKey: "region", label: "Region" },
  { columnKey: "action", label: "Action" },
] as const;

type ColumnKey = (typeof columns)[number]["columnKey"];

const TableDocument: React.FC<DocumentTableProps> = ({filters}) => {
  const [sortConfig, setSortConfig] = useState<{ key: ColumnKey | null, direction: 'ascending' | 'descending' | null }>({
    key: null,
    direction: null,
  });
  //const dispatch = useAppDispatch();
    //const [getAllSDSRequest,getAllSDSRequestResponse] = useFetchAllSDSRequestsMutation();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [items, setItems] = useState<Item[]>(documentData);
    const [searchText, setSearchText] = useState<string>('');
    const [statusFilter, setStatusFilter] = useState<string | number>(''); 
    const [sortedColumn, setSortedColumn] = useState<{ key: keyof Item; isSortedDescending: boolean }>({
        key: 'requestId',
        isSortedDescending: false,
    });
    const [fromDate, setFromDate] = useState<Date | undefined>(undefined);
    const [toDate, setToDate] = useState<Date | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage] = useState<number>(5); 
    const [documentList, setDocumentList] = useState<Document>();
    const [openDocView, setOpenDocView] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
  const navigate = useNavigate();

  const sortedItems = React.useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig.key !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key as ColumnKey].label;
        const bValue = b[sortConfig.key as ColumnKey].label;

        if (aValue < bValue) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (aValue > bValue) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key: ColumnKey) => {
    let direction: 'ascending' | 'descending' = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleEdit = (requestId: string) => {
    navigate(`/sdsrequest/import/${requestId}`);
    // Add your edit logic here (e.g., navigate to an edit page)
  };

  const handleView = (requestId: string) => {
    window.open("http://localhost:3000/sdsrequest/download", "_blank");   
  };

  const filteredItems = React.useMemo(() => {
    return documentData.filter(item => {
      const matchesName = item.document.label.toLowerCase().includes(filters.name.toLowerCase());
      const matchesStatus = filters.status === 'All' || item.status.label === filters.status;

      const issueDate = new Date(item.IssueDate.label);
      const matchesDateRange =
        (!filters.startDate || issueDate >= new Date(filters.startDate)) &&
        (!filters.endDate || issueDate <= new Date(filters.endDate));

      return matchesName && matchesStatus && matchesDateRange;
    });
  }, [filters]);  

  return (
    <TableWrapper>
      <Table aria-label="Styled table" style={{ minWidth: "510px" }}>
        <TableHeader>
          <TableRow>
            {columns.map((column) => (
              <TableHeaderCell key={column.columnKey} onClick={() => requestSort(column.columnKey)}>
                {column.label}
                {sortConfig.key === column.columnKey && (sortConfig.direction === 'ascending' ? ' ▲' : ' ▼')}
              </TableHeaderCell>
            ))}
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredItems.map((item) => (
            <TableRow key={item.requestId.label}>
              {columns.map((column) => (
                <TableCell key={column.columnKey}>
                  <TableCellLayout>
                    {column.columnKey === 'document' ? (
                      <a href={process.env.PUBLIC_URL + item[column.columnKey as ColumnKey].label} target="_blank" rel="noopener noreferrer">
                        {item[column.columnKey as ColumnKey].label}
                      </a>
                    ) : column.columnKey === 'action' ? (
                      <EyeIconWrapper>
                        {
                          <Eye24Regular aria-label="View Document" onClick={() => handleView(item.requestId.label)} />
                        }
                      </EyeIconWrapper>
                    ) : column.columnKey === 'status' ? (
                      <StatusLabel status={item.status.label}>
                        {item.status.label}
                      </StatusLabel>
                    ) : (
                      item[column.columnKey as ColumnKey].label
                    )}
                  </TableCellLayout>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  tr {
    border-bottom: none;
  }

  th {
    padding: 5px 8px;
    background-color: #4952a3;
    color: white;
    cursor: pointer;
    text-align: center;
  }

  td {
    padding: 8px 15px;
    text-align: center;
    white-space: nowrap; /* Prevent line breaks */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Add ellipsis for overflowed text */
  }

  th:first-child, td:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
  }

  th:last-child, td:last-child {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  tbody tr:nth-child(even) {
    background-color: #EAE7E7;
  }
`;

const EyeIconWrapper = styled.span`
  cursor: pointer;
  color: #4952a3;
  &:hover {
    color: #333;
  }
`;

const StatusLabel = styled.span<{ status: string }>`
  color: ${({ status }) => {
    if (status === 'Completed') return 'green';
    if (status === 'Not yet Started') return 'gray';
    if (status === 'Pending') return 'red'; 
    if (status === "In progress") return 'orange';
    return 'black'; 
  }};
`;

export default TableDocument;
