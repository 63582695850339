import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import userReducer from './state/userSlice';
import SDSRequestsReducder from "./state/SDSRequestsSlice";
import { userApi } from './api/userApi';
import { SDSRequestsAPI } from './api/SDSRequestsAPI';

const rootReducer = combineReducers({
  user: userReducer,
  SDSRequests: SDSRequestsReducder,
  [userApi.reducerPath]: userApi.reducer,
  [SDSRequestsAPI.reducerPath]: SDSRequestsAPI.reducer,  
});

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware : (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
    .concat(userApi.middleware)
    .concat(SDSRequestsAPI.middleware)
})

export const persistor = persistStore(store)

// Infer the type of `store`
export type AppStore = typeof store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

setupListeners(store.dispatch);