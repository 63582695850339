import React from 'react';
import styled from 'styled-components';

interface StepProps {
  step: number;
  label: string;
  isActive: boolean;
  isCompleted: boolean;
}

const ProgressSteps: React.FC = () => {
  const steps: StepProps[] = [
    { step: 1, label: 'Download SDS', isActive: true, isCompleted: false },
    { step: 2, label: 'Verify SDS', isActive: true, isCompleted: false },
    { step: 3, label: 'Translate Language', isActive: false, isCompleted: false },
    { step: 4, label: 'Data Extraction', isActive: false, isCompleted: false },
    { step: 5, label: 'Integration', isActive: false, isCompleted: false },
  ];

  return (
    <StepsContainer>
      {steps.map((step, index) => (
        <Step key={step.step} {...step} isLast={index === steps.length - 1} />
      ))}
    </StepsContainer>
  );
};

const Step: React.FC<StepProps & { isLast: boolean }> = ({ step, label, isActive, isCompleted, isLast }) => (
  <StepWrapper>
    <StepNumber isActive={isActive} isCompleted={isCompleted}>
      {isCompleted ? '✓' : step}
    </StepNumber>
    {!isLast && <StepConnector isActive={isActive || isCompleted} />}
    <StepLabel>{label}</StepLabel>
    {isActive && <ActiveIndicator>In progress</ActiveIndicator>}
  </StepWrapper>
);

const StepsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StepNumber = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: ${(props) => (props.isActive || props.isCompleted ? '#228b22' : '#fff')};
  color: ${(props) => (props.isActive || props.isCompleted ? '#fff' : '#11142d')};
  border: 1px solid #4952a3;
`;

const StepConnector = styled.div<{ isActive: boolean }>`
  width: 65px;
  height: 2px;
  background-color: ${props => (props.isActive ? '#4952a3' : '#e0e0e0')};
  position: absolute;
  top: 15px;
  left: 100%;
  @media (max-width: 991px) {
    display: none;
  }
`;

const StepLabel = styled.div`
  margin-top: 8px;
  font-size: 14px;
  color: #11142d;
  text-align: center;
`;

const ActiveIndicator = styled.div`
  color: #d12031;
  font-size: 12px;
  margin-top: 4px;
`;

export default ProgressSteps;