import React, { useState } from 'react';
import styled from 'styled-components';
import Header from './Header';
import FilterSection from './FilterSection';
import DocumentTable from './DocumentTable';
import OutdatedLists from './OutdatedLists';

const SdsDocumentList: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'active' | 'archived'>('active');
  const [filters, setFilters] = useState({
    name: '',
    status: 'All',
    startDate: '',
    endDate: '',
  });

  const handleFilterSubmit = (newFilters: typeof filters) => {
    setFilters(newFilters);
    console.log('Filters submitted:', newFilters);
  };

  return (
    <StyledSdsDocumentList>
      <MainContentWrapper>
        <Header activeTab={activeTab} setActiveTab={setActiveTab} />
        <ContentWrapper>
          <FilterSection onFilterSubmit={handleFilterSubmit} />
          {activeTab === 'active' ? <DocumentTable filters={filters} /> : <OutdatedLists filters={filters} />}
        </ContentWrapper>
      </MainContentWrapper>
    </StyledSdsDocumentList>
  );
};

const StyledSdsDocumentList = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
`;

const MainContentWrapper = styled.div`
  flex-grow: 1;
  overflow-y: hidden; 
`;

const ContentWrapper = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  min-height: 100%;
`;

export default SdsDocumentList;
