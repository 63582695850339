import React, { useState } from 'react';
import { PrimaryButton, TextField } from '@fluentui/react';
import styled from 'styled-components';
import { Link } from 'react-router-dom'; 
import { Eye, EyeOff } from 'react-feather';
import * as Yup from 'yup';
import { FormError, FormInput, PasswordInput } from '../common/FormikComp';
import { ErrorMessage, Field, Form, Formik } from 'formik';

const signInSchema = Yup.object().shape({
  username: Yup.string().matches(/^[a-z]+[a-z0-9._-]+@[a-z-_]+\.(com|co\.[a-z]{2,})$/,'Invalid Username').required('Please Enter Username'),
  password: Yup.string().required('Please Enter Password'),
});

interface LoginFormProps {
  onLogin: (email: string, password: string) => void;
  onGoogleSuccess: (token: string) => void; 
}

interface LoginForm {
  username: string;
  password: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ onLogin, onGoogleSuccess }) => {
  const initialValues: LoginForm = { username: '', password: ''};

  const handleSubmit = (event: LoginForm) => {
    onLogin(event.username, event.password);
  };

  return (
    <FormWrapper>
      <LogoContainer>
        <LogoImage
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/2fa918673f59999a758e1ead2e17e4218e74e0dc4d2829f8a6caae6f1bfef42b?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa"
          alt="Company logo"
        />
      </LogoContainer>
      <FormContainer>
        <Formik
          onSubmit={handleSubmit}
          validationSchema={signInSchema}
          initialValues={initialValues}        
        >
          {() => (
            <Form>
              <Field
                label="Email" required name="username"
                component={FormInput} placeholder = "Enter Your Email"
              />
              <ErrorMessage name="username" component={FormError} />
              <Field
                label="Password" required name="password"
                component={PasswordInput} placeholder="Enter Password"
              />
              <ErrorMessage name="password" component={FormError} />
              <ForgotPassword>
                <StyledLink to="/forgot-password">Forgot password?</StyledLink>
              </ForgotPassword>
              <SubmitButton type="submit" text="Sign in" />
            </Form>
          )}
        </Formik>
        {/* <form onSubmit={handleSubmit}>
          <InputGroup>
            <StyledTextField
              label="Email"
              type="email"
              placeholder="Input your email here"
              value={email}
              onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
            />
          </InputGroup>
          <InputGroup>
            <PasswordContainer>
              <StyledTextField
                label="Password"
                type={passwordVisible ? 'text' : 'password'} 
                placeholder="Input your password here"
                value={password}
                onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
              />
              <PasswordToggle onClick={togglePasswordVisibility} type="button">
                {passwordVisible ? <EyeOff size={20} /> : <Eye size={20} />}
              </PasswordToggle>
            </PasswordContainer>
          </InputGroup>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <ForgotPassword>
            <StyledLink to="/forgot-password">Forgot password?</StyledLink>
          </ForgotPassword>
          <SubmitButton type="submit" text="Sign in" />
        </form> */}
        {/* <OrDivider>Or</OrDivider>
        <SignUpPrompt>
          <span>Doesn't have an account?</span>
          <SignUpLink to="#">Sign up now</SignUpLink>
        </SignUpPrompt> */}
      </FormContainer>
    </FormWrapper>
  );
};

// Styled Components

const PasswordContainer = styled.div`
  position: relative; 
`;

const PasswordToggle = styled.button`
  position: absolute;
  right: 10px; 
  top: 70%; 
  transform: translateY(-50%); 
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none; 
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;

  @media (max-width: 480px) {
    top: 10px;
    right: 10px;
  }
`;

const FormWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  background-color: #ffffff;
  padding: 20px;
`;

const FormContainer = styled.div`
  border-radius: 0px;
  background-color: #ffffff;
  padding: 109px 127px;
  width: 137%;
  max-width: 701px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: left; 
  height: 107vh;

  @media (max-width: 480px) {
    padding: 20px;
    max-width: 90%;
    font: 400 14px/1.2 'Mullish', regular; 
  }
`;

const LogoImage = styled.img`
  width: 178px;
  height: 56px;
`;

const StyledTextField = styled(TextField)`
  .ms-TextField-fieldGroup {
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid #4952a3;
    color: #4952a3;
    height: 50px;
    padding: 8px;
    font-weight: 700;
  }

  .ms-TextField-field {
    padding: 15px 0; 
    height: 100%; 
  }

  .ms-Label {
    font-weight: 700;
  }
`;

const InputGroup = styled.div`
  margin-top: 20px;
`;

const ForgotPassword = styled.div`
  color: #9A9AB0;
  text-align: right;
  margin-top: 17px;
  cursor: pointer;
  font: 400 14px/1.2 'Poppins', regular;
`;

const StyledLink = styled(Link)`
  color: #5541d7;
  text-align: right;
  margin-top: 17px;
  cursor: pointer;
  font-weight: 550; 
  font-size: 14px; 
  line-height: 1.2; 
  font-family: 'Poppins', sans-serif; 
  text-decoration: none; 

  &:hover {
    text-decoration: underline; 
  }
`;

const SubmitButton = styled(PrimaryButton)`
  width: 100%;
  background-color: #d12031;
  color: white;
  border: none;
  margin-top: 30px;
  padding: 16px 0;
  font-weight: 600;
  font-size: 16px;
  border-radius: 8px;

  &:hover {
    background-color: #c11929;
  }
`;

const OrDivider = styled.div`
  color: #9a9ab0;
  text-align: center;
  margin: 20px 0;
  font-size: 12px;
`;

const GoogleSignInButton = styled(PrimaryButton)`
  width: 100%;
  border-radius: 8px;
  background-color: transparent;
  border: 2px solid #4952a3;
  color: #4952a3;
  padding: 16px 0;

  &:hover {
    background-color: rgba(73, 82, 163, 0.1);
  }
`;

const SignUpPrompt = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  font-size: 14px;
`;

const SignUpLink = styled(Link)`
  color: #5541d7;
  font-weight: 600;
  margin: -3px;
  margin-left: 5px;
`;

// const ErrorMessage = styled.div`
//   color: red;
//   margin-top: 10px;
// `;

export default LoginForm;
