import React from 'react';
import styled from 'styled-components';
 
const Header: React.FC = () => {
  return (
    <HeaderWrapper>
      <Logo loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/d028ec9c8c8b80c54862f2e1af731c0a6b8c5354e0e8c1c944cb7e4c55527d47?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" alt="Company Logo" />
      <DashboardText>DASHBOARD</DashboardText>
    </HeaderWrapper>
  );
};
 
const HeaderWrapper = styled.header`
  align-self: flex-start;
  display: flex;
  font-family: Mulish, sans-serif;
  color: var(--Light-Text-Color-Title, #11142d);
  font-weight: 700;
  white-space: nowrap;
  text-transform: uppercase;
  letter-spacing: 0.8px;
  margin-top: 19px;
  @media (max-width: 991px) {
    white-space: initial;
    margin-top: 40px;
  }
`;
 
const Logo = styled.img`
  aspect-ratio: 0.83;
  object-fit: contain;
  object-position: center;
  width: 81px;
`;
 
const DashboardText = styled.h1`
  align-self: flex-start;
  margin-top: 39px;
  flex-basis: auto;
  font-size: 16px;
`;
 
export default Header;