import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Attach48Regular } from '@fluentui/react-icons'; // Import the Attach icon

interface StatusIndicatorProps {
  label: string;
  status: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const StatusIndicator: React.FC<StatusIndicatorProps> = ({ label, status, onClick }) => {
  const [isUpload, setIsUpload] = useState<boolean>(false);

  useEffect(() => {
    if(label === "Upload to ERP") setIsUpload(true);
  })
  
  return (
    <Wrapper isUpload={isUpload}>
      <Label isUpload={isUpload}>{label}</Label>
      {label === "Attachment" ? (
        <IconWrapper>
          <Status>{status}</Status>
          <AttachIconWrapper onClick={onClick}>
            <Attach48Regular width={20} height={20}/>
          </AttachIconWrapper>
        </IconWrapper>
      ) : (
        <Status>{status}</Status>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{isUpload: boolean}>`
  display: flex;
  width: ${(props) => (props.isUpload ? "70%" : "100%")};
  flex-direction: row;
  letter-spacing: 0.08px;
  font: 700 16px/1.2 Poppins, sans-serif;
  margin-bottom:${(props) => (props.isUpload ? "0px" : "10px")};
  margin-top: ${(props) => (props.isUpload ? "45px" : "0px")};  
`;

const Label = styled.div<{isUpload: boolean}>`
  color: #11142d;
  width:${(props) => (props.isUpload ? "60%" : "30%")};
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center; // Align items vertically
`;

const Status = styled.div`
  border-radius: 8px;
  background-color: #fff;
  width: 70%;
  color: #d12031;
  padding-right: 10px;
`;

const AttachIconWrapper = styled.div`
  width: 20px; // Set fixed width
  height: 20px; // Set fixed height
  display: flex;
  align-items: center; // Center icon vertically
  justify-content: center; // Center icon horizontally
  cursor: pointer;
  margin-left:5px;
`;

export default StatusIndicator;
