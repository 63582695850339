import React from 'react';
import styled from 'styled-components';

interface FormFieldProps {
  label: string;
  placeholder: string;
  isFileInput?: boolean;
}

const FormField: React.FC<FormFieldProps> = ({ label, placeholder, isFileInput = false }) => {
  return (
    <FieldWrapper>
      <Label htmlFor={label}>{label}</Label>
      {isFileInput ? (
        <FileInput id={label} placeholder={placeholder} />
      ) : (
        <Input id={label} placeholder={placeholder} />
      )}
    </FieldWrapper>
  );
};

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  color: #11142d;
  font-weight: 600;
  margin-bottom: 8px;
`;

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  padding: 16px;
  font-size: 16px;
  color: #9a9ab0;
  &::placeholder {
    color: #9a9ab0;
  }
`;

const FileInput = styled(Input)`
  background-color: #ededed;
  cursor: pointer;
`;

export default FormField;