import React, { useState } from 'react';
import { Stack, Label } from '@fluentui/react';
import styled from 'styled-components';

interface EditUpdateSectionProps {
  classifications: Array<string>;
  selectedItem: string;
  onClick?: (classification: string, index: number) => void;
  

}
const EditUpdateSection: React.FC<EditUpdateSectionProps> = ({classifications, selectedItem, onClick}) => { 
  const handleItemClick = (classification: string, index: number) => {
    if (onClick) {
      if(index === 0 || index === 1) onClick(classification, 1);
      else {onClick(classification, 2)};
    }
  }; 
  return (
    <Stack tokens={{ childrenGap: 20 }} horizontalAlign="center" styles={{ root: { padding: '30px' } }}>
      <SectionLabel>Edit & Update</SectionLabel>
      <Accent />
      <EditList>
        {classifications.map((classification, index) => (
          <EditListItem
            key={index}
            onClick={() => handleItemClick(classification, index)}
            selected={selectedItem === classification} // Check if this item is selected
          >
            {classification}
          </EditListItem>
        ))}
      </EditList>
    </Stack>
  );
};

// Styled Components
const SectionLabel = styled(Label)`
  font-size: 40px;
  font-weight: bold;
  color: white;
  text-align: center;
`;

const EditList = styled.div`
  background-color: rgb(73, 82, 163);
  border-radius: 8px;
  height: 580px; 
  overflow-y: auto; /* Ensure scrollbar appears when necessary */
  width: 100%;
  
  /* Custom scrollbar styles for webkit browsers */
  ::-webkit-scrollbar {
    width: 6px; /* Thinner scrollbar */
  }

  ::-webkit-scrollbar-thumb {
    background-color: black; /* Black scrollbar thumb */
    border-radius: 18px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: darkgray; /* Darker thumb on hover */
  }

  /* Custom scrollbar styles for Firefox */
  scrollbar-width: thin; /* Make scrollbar thin */
  scrollbar-color: black transparent; /* Black thumb and transparent track */
`;

const EditListItem = styled.div<{ selected?: boolean }>`
  font-size: 16px; /* Set font size to 16px */
  font-family: 'Poppins', sans-serif; /* Set font family to Poppins */
  font-weight: 400; /* Set font weight to Medium */
  padding: 5px 10px; /* Added padding for better click area */
  color: white; /* Set font color to white */
  cursor: pointer; /* Change cursor to pointer on hover */
  
  ${({ selected }) => selected && `
    font-style: italic; /* Italic style for selected text */
    font-weight: 600; /* Change to bold for selected text if needed */
    background-color: rgba(255, 255, 255, 0.1); /* Add hover effect */
  `}

  &:hover {
    background-color: rgba(255, 255, 255, 0.1); /* Add hover effect */
  }
`;

const Accent = styled.div`
  width: 64%;
  height: 4px;
  background-color: #f24c4c;
  margin: 20px 0;
`;

export default EditUpdateSection;
