import React, { useState } from 'react';
import {
  Text,
  createTheme,
  Nav,
  INavLinkGroup,
  IconButton,
} from '@fluentui/react';
import './App.css';
import logo from './logo_only.jpg';
import logo2 from './logo_with_text.jpg';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import DocumentList from './documents/DocumentList';
import { FluentProvider, teamsLightTheme, Toast, Toaster, ToastTitle, useId, useToastController } from '@fluentui/react-components';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
//import Router from './routes';
import { UserManagement } from './pages/userManagement';
// import Feedback from './pages/feedback';
import { useAppSelector } from './store/hooks';
import { isAuthenticated } from './store/state/userSlice';
import { SignIn } from './pages/authentication/SignIn';
// import { ForgotPassword } from './pages/authentication/ForgotPassword';
import SdsDownloadPage from './pages/sdsRequests/DownloadSDS/SDSDownloadPage';
import VerifySDSPage from './pages/sdsRequests/VerifySDS/VerifySDSPage';
import SDSProcessingPage from './pages/sdsRequests/SDSProcessing/SDSProcessingPage';
import IntegrationProcess from './pages/sdsRequests/IntegrationProcess/IntegrationProcess';
import Sidebar from './pages/sidebar-ui/Sidebar';
import SdsDocumentList from './pages/documents-ui/SDSDocumentList';
import LoginPage from './pages/login-ui/LoginPage';
import LoginForm from './pages/login-ui/LoginForm';
import TicketSubmissionPage from './pages/ticketSubmission-ui/TicketSubmissionPage';
import ForgotPasswordPage from './pages/forgotPassword-ui/ForgotPasswordPage';
import ResetPassword from './pages/resetPassword-ui/ResetPassword';
import ContactForm from './pages/feedback-ui/ContactForm';
import HelpCenter from './pages/contactus-ui/HelpCenter';
// import OutdatedSDSPage from './pages/DocumentList/OutdatedSDSPage';
// import ActiveList from './pages/DocumentList/SDSDocumentList';
// import OutdatedLists from './pages/DocumentList/ArchivedList';
 
 
initializeIcons();
 
const myTheme = createTheme({
  palette: {
    themePrimary: '#1e40af',
    neutralLighterAlt: '#f8f8f8',
    neutralLighter: '#f4f4f4',
    neutralLight: '#eaeaea',
    neutralTertiary: '#595959',
    neutralPrimary: '#000000',
    white: '#ffffff',
  },
});
 
// Sidebar menu items with Fluent UI icons
const navLinkGroups: INavLinkGroup[] = [
  {
    links: [
      { name: 'Home', url: '/', key: 'key1', icon: 'Home' },
      { name: 'sdsrequest', url: '/', key: 'document', icon: 'TextDocument' },
      { name: 'Users', url: '/user', key: 'key3', icon: 'People' },
      { name: 'Settings', url: '#', key: 'key4', icon: 'Settings' },
      { name: 'Contact US', url: '#', key: 'key5', icon: 'Phone' },
      { name: 'Feedback', url: '/feedback', key: 'feedback', icon: 'Handwriting' },
      {name : 'Sruthi Janani', url: '/loggedIn', key: 'key6', icon: 'UserOptional'}
    ],
  },
];
 
// Define the props interface
interface ChildProps {
  isSidebarVisible: boolean; // Specify the type of the prop
}
 
// Header component
 
const Header: React.FC<ChildProps> = ({ isSidebarVisible }) => {
  return (
    <header style={headerStyle}>
      <div style={{ padding: '10px', height: '45px', width: isSidebarVisible ? '230px': '45px', textAlign: 'center', backgroundColor: 'white' }}>
     
      {!isSidebarVisible && <img src={logo} width="45" height="50" alt='Logo'/>}
      {isSidebarVisible && <img src={logo2} width="200" height="50" alt='Logo'/>}
      </div>
      <Text variant="large" style={{color: 'white', marginLeft: '10px', fontWeight: 500}}>Safety Data Center</Text>
    </header>
  );
};
 
// Styling for the header
const headerStyle: React.CSSProperties = {
  width: '100%',
  height: '60px',
  backgroundColor: '#1e40af',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  paddingLeft: '0px',
  position: 'fixed',
  top: 0,
  zIndex: 1000,
};
 
export const App: React.FunctionComponent = () => {
  // State to manage which menu item is selected
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [selectedKey, setSelectedKey] = useState('key1'); // Default to "Home"
  const isUserAuthenticated = useAppSelector(isAuthenticated);
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
 
 
  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };
 
  const toastError = (data: any) => {
    dispatchToast(
      <Toast>
        <ToastTitle>{data}</ToastTitle>
      </Toast>,
      { position: "top", intent: "error"}
    )
  }
 
  const clearLinkNames = (navLinkGroups: INavLinkGroup[]) => {
    return navLinkGroups.map(group => ({
      ...group,
      links: group.links.map(link => ({
        ...link,
        name: '', // Set the name property to an empty string
      })),
    }));
  };
 
  const onNavLinkClick = (ev?: React.MouseEvent<HTMLElement>, item?: any) => {
    //ev?.preventDefault();
    console.log("Item", item);
    setSelectedKey(item?.key);
  };
 
  return (
    // <ThemeProvider applyTo="body" theme={myTheme}>
    <Router>
      <FluentProvider theme={teamsLightTheme}>
        <div className="app-container" style={{ paddingTop: '-60px' }}> {/* Adjust padding for the header */}
          {/* Static Header */}
          {/* <Header isSidebarVisible={isSidebarVisible} /> */}
 
          {/* Sidebar Menu */}
          {/* <div style={{ height: '100vh', backgroundColor: '#eaeaea', position: 'fixed', width: isSidebarVisible ? '250px' : '60px', top: 0, left: 0, zIndex: 100, transition: 'width 0.3s', }}>
            <IconButton
              iconProps={{ iconName: !isSidebarVisible ? 'PageRight' : 'PageLeft' }}
              onClick={toggleSidebar}
              color='primary'
              style={{
                fontSize: '24px',
                top: '60px',
                left: !isSidebarVisible ? '50px' : '231px',
                zIndex: 100,
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                position: 'absolute'
              }} // Increase size of the toggle button icon
            />
            <Nav
              groups={isSidebarVisible ? navLinkGroups : clearLinkNames(navLinkGroups)}
              selectedKey={selectedKey}  // Set the selected key
              onLinkClick={onNavLinkClick}  // Handle click event
              styles={{
                root: { width: '100%', paddingTop: '75px' }, // Adjusted width for the sidebar
                link: {
                  selectors: {
                    ':hover': { backgroundColor: '#eaeaea' },
                    '.ms-Nav-linkText': { fontSize: '17px' }, // Adjust text size
                    '.ms-Icon': { fontSize: '27px' },
                  },
                },
              }}
            />
          </div> */}
          <Sidebar/>
 
          {/* Main Content */}
          <div className="main-content" style={{
            // marginLeft: isSidebarVisible ? '250px' : '20px',
            marginTop: '5px', // Adjust this value based on the toggle button
            height: '96vh', // Adjust based on the height of your toggle button
            overflowY: 'hidden',
            transition: 'margin-left 0.3s'
          }}>
            <Routes>
               <Route path="/" element={isUserAuthenticated ? <Navigate to='/document' /> : <LoginPage toastError={toastError}/>} /> 
              <Route path="/document" element={<SdsDocumentList />} />
              <Route path="/user" element={<UserManagement toastError={toastError}/>} />
              <Route path="/forgot-password" element={<ForgotPasswordPage/> } />  
              <Route path="/reset-password" element={<ResetPassword/>} />        {/* <Route path="/forgot-password" element={<ForgotPassword/>} /> */}
              <Route path="/feedback" element={<ContactForm/>}/>
              <Route path="/askmarie" element={<HelpCenter/>}/>
              <Route path="/sdsrequest/download" element={<SdsDownloadPage />} />
              <Route path="/sdsrequest/verify" element={<VerifySDSPage />} />
              <Route path="/sdsrequest/import/:requestId" element={<TicketSubmissionPage />} />
              <Route path="/sdsrequest/processing" element={<SDSProcessingPage />} />
              <Route path="/sdsrequest/integration" element={<IntegrationProcess/>} />
              <Route path="/documentlist/activelist" element={""} />
              {/* <Route path="/outatedlist" element={<OutdatedLists/>} /> */}
           
            </Routes>
 
 
            {/* Toggle Menu Button */}
 
 
            {/* <Stack horizontalAlign="center" verticalAlign="center" verticalFill tokens={{ childrenGap: 15 }}>
            <DocumentList></DocumentList>
          </Stack> */}
            {/* <Stack horizontalAlign="center" verticalAlign="center" verticalFill tokens={{ childrenGap: 15 }}>
            <Text variant="xxLarge">Welcome to your Fluent UI app</Text>
            <Text variant="large">
              Use the menu icon to explore different sections like Home, Documents, Users, and Settings.
            </Text>
          </Stack> */}
          </div>
          <Toaster toasterId={toasterId} />
        </div>
      </FluentProvider>
    </Router>
    // </ThemeProvider>
  );
};