import React, { useState } from 'react';
import styled from 'styled-components';

interface DatePickerProps {
  label: string;
  placeholder: string;
  value: string
}

const DatePicker: React.FC<DatePickerProps> = ({ label, placeholder, value }) => {
  const [selectedDate, setSelectedDate] = useState<string>(value);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(event.target.value);
  };

  return (
    <DatePickerWrapper>
      <Label htmlFor={label}>{label}</Label>
      <InputWrapper>
        <Input 
          id={label} 
          type="string" 
          value={selectedDate} 
          onChange={handleChange} 
          placeholder={placeholder} 
        />
        <CalendarIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/9b5df7349ca6aff7b15020de890f7120e99d2f5ae970666d45a0f30f9d2fef78?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" alt="Calendar" />
      </InputWrapper>
    </DatePickerWrapper>
  );
};

const DatePickerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Label = styled.label`
 color: #11142d;
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 16px; // Use font-size instead of font
  line-height: 1.2; // Use line-height separately
  font-family: 'Poppins', sans-serif; // Specify the font-family explicitly
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5541d7;
  background-color: #fff;
  padding: 14px 22px;
  font: 600 16px Poppins, sans-serif;
  color: #7a7a8a;
  // Disable pointer events on hover
  &:hover {
    cursor: not-allowed; // Show not-allowed cursor
  }

  // Optional: To prevent cursor change when input is disabled
  &:disabled {
    cursor: not-allowed; // Ensures disabled state shows not-allowed cursor
    background-color: #f0f0f0; // Change background to indicate it's disabled
  }
`;

const CalendarIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  &:hover {
    cursor: not-allowed; // Show not-allowed cursor
  }

  // Optional: To prevent cursor change when input is disabled
  &:disabled {
    cursor: not-allowed; // Ensures disabled state shows not-allowed cursor
    background-color: #f0f0f0; // Change background to indicate it's disabled
  }
`;

export default DatePicker;
