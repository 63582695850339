import React from 'react';
import styled from 'styled-components';

const ReviewForm: React.FC = () => {
  return (
    <FormSection>
      <Label htmlFor="reviewTitle">Review title</Label>
      <Input id="reviewTitle" type="text" aria-label="Review title" />
      <Label htmlFor="reviewContent">Tell us more about your experience</Label>
      <TextArea id="reviewContent" aria-label="Product review" />
    </FormSection>
  );
};

const FormSection = styled.div`
padding-left: 55px;
  width: 100%;
`;

const Label = styled.label`
  display: block;
     margin-bottom: 5px;
    margin-top: 6px;
`;

const Input = styled.input`
  border-radius: 12px;
  background-color: #fff;
  border: 1px solid #dcdbdd;
  padding: 14px;
  width: 70%;
  margin-bottom: 4px;
`;

const TextArea = styled.textarea`
  border-radius: 12px;
  background-color: #fff;
  width: 70%;
  height: 140px;
  border: 1px solid #dcdbdd;
  padding: 10px;
  resize: vertical;
`;

export default ReviewForm;