import { MessageBar, MessageBarBody, MessageBarIntent, MessageBarTitle } from "@fluentui/react-components";
import React from "react";

interface CustomMessageProps {
  intent: MessageBarIntent;
  message: string;
}

export const CustomMessage: React.FC<CustomMessageProps> = (props) => {

  return(
    <MessageBar intent={props.intent} style={{ margin: 'auto', width: 'fit-content'}}>
      <MessageBarBody>
        <MessageBarTitle>{props.message}</MessageBarTitle>
      </MessageBarBody>
    </MessageBar>
  );
}