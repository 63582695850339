import React, { useState } from 'react';
import { Stack, TextField, Label } from '@fluentui/react';
import styled from 'styled-components';

const ExposureControl: React.FC = () => {
  const [controlParameters, setControlParameters] = useState("Contains no substances with occupational exposure limit values.");
  const [engineeringControls, setEngineeringControls] = useState("Handle only in a place equipped with local exhaust (or other appropriate exhaust).");
  const [personalProtectiveEquipment, setPersonalProtectiveEquipment] = useState(
    "Respiratory protection \nUse NIOSH/MSHA approved respirators following manufacturer's recommendations where dust or fume may be generated.\nHand protection \nNitrile rubber gloves.\nEye protection \nSafety glasses or chemical splash goggles.\nSkin and body protection \nProtective measures \nWear suitable protective equipment.\nHygiene measures \nWash hands before breaks and at the end of workday.\nUse protective skin cream before handling the product.\nTake off immediately all contaminated clothing and wash it before reuse."
  );

  return (
    <Stack tokens={{ childrenGap: 3 }}>
      <SectionHeader>
        <SectionIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4de6a32e7c5c824224545ca24ff74a7e0dc3a4d20856b27b6abb547f0aec8f9?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" alt="Icon" />
        <SectionTitle>EXPOSURE CONTROLS/PERSONAL PROTECTION</SectionTitle>
      </SectionHeader>
      <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { width: '100%' } }}>
        <LabeledInput label="Control Parameters" value={controlParameters} onChange={setControlParameters} />
        <LabeledInput label="Engineering Controls" value={engineeringControls} onChange={setEngineeringControls} />
      </Stack>
      <LabeledInput label="Personal Protective Equipment" value={personalProtectiveEquipment} onChange={setPersonalProtectiveEquipment} />
    </Stack>
  );
};

const LabeledInput = ({ label, value, onChange }: { label: string; value?: string; onChange: (value: string) => void }) => (
  <InputContainer>
    <Label>{label}</Label>
    <TextField 
      styles={textFieldStyles} 
      value={value} 
      onChange={(e, newValue) => onChange(newValue || '')} 
      multiline 
      rows={3} 
    />
  </InputContainer>
);

const textFieldStyles = {
  fieldGroup: { borderRadius: 8, border: '1px solid #4952a3', height: 'auto' },
  field: { padding: '5px 10px', fontSize: 14 },
};

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #4952a3;
`;

const SectionIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const SectionTitle = styled.h2`
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
`;

const InputContainer = styled.div`
  flex-grow: 1; /* Allow the input container to take the remaining width */
  width: 100%; /* Ensure full width */
`;

export default ExposureControl;
