import { createApi } from "@reduxjs/toolkit/query/react";
import axiosUserBaseQuery from "./axoisUserBaseQuery";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: axiosUserBaseQuery(),
  tagTypes: ["user"],
  endpoints: (builder) => ({
    getAllUsers: builder.mutation({
      query: (body) => {
        return {
          url: "api/user/findAll",
          method: "POST",
          body
        };
      },
    }),
    verifyUserLogin: builder.mutation({
      query: (body) => {
        return {
          url: "api/user/verify",
          method: "POST",
          body,
        };
      },
    }),
    loginUser: builder.mutation({
      query: (body) => {
        return {
          url: "api/default-services/login",
          method: "POST",
          body,
        };
      },
    }),
    addNewUser: builder.mutation({
      query: (body) => {
        return {
          url: "api/user",
          method: "POST",
          body,
        };
      },
    }),
    updateUser: builder.mutation({
      query: (body) => {
        return {
          url: "api/user",
          method: "PUT",
          body,
        };
      },
    }),
    deleteUser: builder.mutation({
      query: (id) => {
        return {
          url: `api/user/${id}`,
          method: "DELETE"
        };
      },
    }),
    getUserById: builder.mutation({
      query: (body) => {
        return {
          url: "api/user/" + body.id,
          method: "GET",
        };
      },
    }),
  }),
});
export const {
  useGetAllUsersMutation,
  useAddNewUserMutation,
  useDeleteUserMutation,
  useGetUserByIdMutation,
  useUpdateUserMutation,
  useLoginUserMutation,
  useVerifyUserLoginMutation
} = userApi;
