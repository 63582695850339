import React from 'react';
import { Stack, Text } from '@fluentui/react';

const Header: React.FC = () => {
  return (
    <Stack horizontalAlign="end" styles={{ root: { padding: '47px 30px', height: '15%' , marginTop: '-8px' , width: 'calc(123%)', transition: 'width 0.3s ease' } }}>
      <Text variant="xxLarge" styles={{ root: { fontWeight: 'bold' } }}>
        Ask Marie
      </Text>
    </Stack>
  );
};

export default Header;
