/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState }  from "react";
import { Formik, Form, Field as FormikField, ErrorMessage } from 'formik';
import { DropdownInput, FormError, FormInput } from "../common/FormikComp";
import { Button, Field, Switch, Title3 } from "@fluentui/react-components";
import { IconButton } from "@fluentui/react";
import * as Yup from 'yup';
import { User } from "./types";
import { useAddNewUserMutation, useUpdateUserMutation } from "../../store/api/userApi";
import OverlayLoader from "../common/OverlayLoader";

interface UserFormProps {
  isEdit: boolean;
  closeDialog(getData: boolean): void;
  editRecord: User | null;
  toastError(data: string, intent: string): void; 
}

const userSchema = Yup.object().shape({
  firstName: Yup.string().required('Please Enter First Name').matches(/^[a-zA-Z]+[a-zA-Z ]+[a-zA-Z]+$/, { message: 'First Name must be alphabetic' }),
  lastName: Yup.string().required('Please Enter Last Name').matches(/^[a-zA-Z]+[a-zA-Z ]+[a-zA-Z]+$/, { message: 'Last Name must be alphabetic' }),
  emailId: Yup.string().matches(/^[a-z]+[a-z0-9._-]+@[a-z-_]+\.(com|co\.[a-z]{2,})$/,'Invalid Email').required('Please Enter Email'),
  username: Yup.string().matches(/^[a-z]+[a-z0-9._-]+@[a-z-_]+\.(com|co\.[a-z]{2,})$/,'Invalid Username').required('Please Enter Username'),
});

export const UserForm: React.FC<UserFormProps> = (props: UserFormProps) => {
  const [ editData ] = useState(props.editRecord === null ? 
    {
      id: null,
      firstName: '',
      lastName: '',
      emailId: '',
      username: '',
      enabled: false,
      roles: []
    } : props.editRecord);
  const [ isEdit ] = useState<boolean>(props.editRecord !== null);
  const [ loader, setLoader ] = useState<boolean>(false);
  const [ addUser, addUserResponse ] = useAddNewUserMutation();
  const [ updateUser, updateUserResponse ] = useUpdateUserMutation();

  const handleSubmit = (e: any) => {
    setLoader(true);
    if(isEdit)
      updateUser(e);
    else
      addUser(e);
  }

  useEffect(() => {
    if(addUserResponse.isSuccess) {
      setLoader(false);
      props.toastError("Record Added Successfully", "success");
      props.closeDialog(true);
    }
    if(addUserResponse.isError) {
      // if(typeof addUserResponse.error?.message === "string")
      //   props.toastError(addUserResponse.error?.message as string, "error");
      // else
      //   props.toastError(addUserResponse.error?.message?.message, "error");
      setLoader(false);
    }
  }, [addUserResponse]);

  useEffect(() => {
    if(updateUserResponse.isSuccess) {
      setLoader(false);
      props.toastError("Record Added Successfully", "success");
      props.closeDialog(true);
    }
    if(updateUserResponse.isError) {
      // if(typeof updateUserResponse.error?.message === "string")
      //   props.toastError(updateUserResponse.error?.message as string, "error");
      // else
      //   props.toastError(updateUserResponse.error?.message?.message, "error");
      setLoader(false);
    }
  }, [updateUserResponse]);

  return(
    <>
      {loader && <OverlayLoader/>}
      <div style={{ display: 'flex', justifyContent: 'space-between',margin: '0px 10px' }}>
        <Title3 style={{ color: '#4f52b2'}}>{props.isEdit ? "Edit User" : "Add User"}</Title3>
        <IconButton iconProps={{ iconName: 'Cancel' }} title="Cancel" onClick={() => props.closeDialog(false)}/>
      </div>
      <Formik
        initialValues={editData}
        onSubmit={handleSubmit}
        validationSchema={userSchema}
      >
        {({setFieldValue}) => (
          <Form style={{ margin: '0px 10px', display: 'flex', justifyContent: 'space-between', flexDirection: 'column', gap: 5 }}>
            <FormikField
              label="First Name" required name="firstName" orientation="horizontal"
              component={FormInput} size='medium'
            />
            <ErrorMessage name="firstName" component={FormError} />
            <FormikField
              label="Last Name" required name="lastName" orientation="horizontal"
              component={FormInput} size='medium'
            />
            <ErrorMessage name="lastName" component={FormError} />
            <FormikField
              label="Email" required name="emailId" orientation="horizontal"
              component={FormInput} size='medium'
            />
            <ErrorMessage name="emailId" component={FormError} />
            <FormikField
              label="Username" required name="username" orientation="horizontal"
              component={FormInput} size='medium'
            />
            <ErrorMessage name="username" component={FormError} />
            <FormikField
              label="Roles" required name="roles" orientation="horizontal"
              component={DropdownInput} size='medium' options={[{ id: 1, name:"ROLE_ADMIN"},
                {id: 2, name: "ROLE_PROCUREMENT_USER"},{id: 3, name: "ROLE_ANALYST_USER"}]}
            />
            <ErrorMessage name="roles" component={FormError} />
            <FormikField
              required name="enabled"
            >
              {({ field }: any) => (
                <Field label="Active" orientation="horizontal" required>
                  <Switch
                    {...field}
                    checked={field.value}
                    onChange={(event) => setFieldValue(field.name, event.target.checked)}
                  />
                </Field>
              )}
            </FormikField>
            <Button
              appearance='primary' type='submit' size='large'
              style={{ width: '100%', marginTop: 20 }}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}