import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '..';

export interface SDSRequeststate {
  data: any[];
  totalRecords: number | 0;
  totalPages: number | 0;
  currentPage: number | 0;
}

const initialState: SDSRequeststate = {
  data: [],
  totalRecords: 0,
  totalPages: 0,
  currentPage: 0
}

export const SDSRequestslice = createSlice({
  name: 'SDSRequests',
  initialState,
  reducers: {
    setSDSRequests: (state, action) => {
      state.data = action.payload.data;
      state.totalPages = action.payload.totalPages;
      state.totalRecords = action.payload.totalRecords;
      state.currentPage = action.payload.currentPage;
    },
    // addNewAsset: (state: any, action) => {
    //   state.data = [action.payload.data, ...state.data];
    // },
    // reset: (state) => {
    //   state = initialState;
    // },
  },
})

// Action creators are generated for each case reducer function
export const { setSDSRequests } = SDSRequestslice.actions;

export const data = (state: RootState) => state.SDSRequests;

export default SDSRequestslice.reducer;