import React, { useState } from 'react';
import styled from 'styled-components';

interface FilterSectionProps {
  onFilterSubmit: (filters: {
    name: string;
    status: string;
    startDate: string;
    endDate: string;
  }) => void;
}

const FilterSection: React.FC<FilterSectionProps> = ({ onFilterSubmit }) => {
  // State management for filter fields
  const [name, setName] = useState<string>('');
  const [status, setStatus] = useState<string>('All');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');

  // Handle input changes
  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setStatus(e.target.value);
  };

  const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  // Handle form submission
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onFilterSubmit({ name, status, startDate, endDate });
  };

  return (
    <StyledFilterSection onSubmit={handleSubmit}>
      {/* Search by Name Section */}
      <SearchByName>
        <Label>Search by Name</Label>
        <DocumentInput>
          <input
            type="text"
            placeholder="Document Name"
            value={name}
            onChange={handleNameChange}
          />
        </DocumentInput>
      </SearchByName>

      {/* Filter By Status Section */}
      <SearchByType>
        <Label>Filter by Status</Label>
        <Select value={status} onChange={handleStatusChange}>
          <option value="All">All</option>
          <option value="Pending">Pending</option>
          <option value="Inprogress">In Progress</option>
          <option value="Completed">Completed</option>
          <option value="Failed">Failed</option>
        </Select>
      </SearchByType>

      {/* Date Filter Section */}
      <DateInput>
        <Label>From</Label>
        <input type="date" value={startDate} onChange={handleStartDateChange} />
      </DateInput>
      <DateInput>
        <Label>To</Label>
        <input type="date" value={endDate} onChange={handleEndDateChange} />
      </DateInput>

      {/* Submit Button */}
      <SubmitButton type="submit">Submit</SubmitButton>
    </StyledFilterSection>
  );
};

const StyledFilterSection = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px ;
  background-color: #f9f9f9;
`;

const Label = styled.label`
  color: #202020;
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
`;

const SearchByName = styled.div`
  display: flex;
  flex-direction: column;
  width: 22%;
`;

const SearchByType = styled.div`
  display: flex;
  flex-direction: column;
  width: 16%;
`;

const DocumentInput = styled.div`
  input {
    border-radius: 12px;
    border: 1px solid #dcdbdd;
    padding: 10px 18px;
    font-size: 14px;
    color: #47464a;
    width: 100%;
  }
`;

const Select = styled.select`
  border-radius: 12px;
  padding: 10px;
  font-size: 14px;
  color: #47464a;
  border: 1px solid #dcdbdd;
  width: 100%;
`;

const DateInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 18%;
  input {
    border-radius: 12px;
    padding: 10px;
    font-size: 14px;
    color: #47464a;
    border: 1px solid #dcdbdd;
    width: 100%;
  }
`;

const SubmitButton = styled.button`
  background-color: #d12031;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 12px;
  font-size: 14px;
  cursor: pointer;
  width: 12%;
  height: 44px;

  /* Center the button vertically */
  align-self: flex-end;
  margin-top: auto;
`;

export default FilterSection;
