import React from 'react';
import styled from 'styled-components';
import TicketForm from './TicketForm';
import InfoSection from './InfoSection';
import { useParams } from 'react-router-dom';

const TicketSubmissionPage: React.FC = () => {
  const { requestId } = useParams<{ requestId: string }>(); // Extract requestId from URL

  return (
    console.log("Request ID", requestId),
    <PageWrapper>
      <ContentContainer>
        <InfoSection />
        <TicketForm requestId={requestId}/>
      </ContentContainer>
    </PageWrapper>
  );
};

const PageWrapper = styled.main`
  background-color: #4952a3;
  display: flex;
  justify-content: center;
  @media (max-width: 991px) {
    padding: 20px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

export default TicketSubmissionPage;
