import React from 'react';
import { Stack, Text, Link, Image } from '@fluentui/react';

interface HelpCardProps {
  iconSrc: string;
  title: string;
  description: string;
  learnMoreLink: string;
  onLearnMoreClick: (event: React.MouseEvent<HTMLElement>, title: string) => void; // Update prop type
}

const HelpCard: React.FC<HelpCardProps> = ({ iconSrc, title, description, learnMoreLink, onLearnMoreClick }) => {
  return (
    <Stack
      tokens={{ childrenGap: 15 }}
      styles={{ root: { padding: 12, borderRadius: 8, background: '#fff', height: '98%', justifyContent: 'space-between', marginTop: '-3px' } }}
    >
      <Image src={iconSrc} alt="" width={50} />
      <Stack>
        <Text variant="mediumPlus">{title}</Text>
        <Text variant="small">{description}</Text>
      </Stack>
      {/* Use onLearnMoreClick to pass the event and title */}
      <Link 
        styles={{ root: { marginTop: 'auto', color: '#d12031', cursor: 'pointer' } }} 
        onClick={(event) => onLearnMoreClick(event, title)} // Call the prop function with event and title
      >
        Click Here
      </Link>
    </Stack>
  );
};

export default HelpCard;
