import React from 'react';
import styled from 'styled-components';
 
const IllustrationSection: React.FC = () => {
  return (
    <IllustrationWrapper>
      <IllustrationImage loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/ac793d0735c40fc1679f57718b2c85fe196f886ed1a71c7da6c27f9d1e05f210?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" alt="Illustration" />
      <Accent />
      <IllustrationText>
        It happens to the best of us. Let's reset <br /> your password quickly!
      </IllustrationText>
    </IllustrationWrapper>
  );
};
 
const IllustrationWrapper = styled.aside`
  background-color: rgba(73, 82, 163, 1);
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  width: 100%;
  padding: 59px 0px 0px 0px;
  font: 500 16px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
    // padding: 13px 20px 100px;
  }
`;
 
const IllustrationImage = styled.img`
  width: 50%;
   height:80%;
  // aspect-ratio: 0.69;
  // object-fit: cover;
  // object-position: center;
  // align-self: flex-start;
  @media (max-width: 991px) {
    max-width: 30%;
  }
`;
 
const Accent = styled.div`
  background-color: rgba(242, 76, 76, 1);
  margin-left: 23px;
  width: 174px;
  height: 4px;
`;
 
const IllustrationText = styled.p`
  margin-bottom: 90px;
`;
 
export default IllustrationSection;