import React, { useState } from 'react';
import styled from 'styled-components';

// Assume these components are defined elsewhere
import FormField from './FormField';
import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import StatusIndicator from './StatusIndicator';

interface DataExtractionPage{
    attachment: string,
    dataExtracted: string,
    dataValidation: string
    invokeOpenEditAndUpdate?: (event: React.MouseEvent<HTMLDivElement>) => void;
}
const DataExtractionPage: React.FC<DataExtractionPage> = ({attachment, dataExtracted, dataValidation, invokeOpenEditAndUpdate}) => {
  return (
    <FormWrapper>   
      <StatusIndicator label = "Attachment" status={attachment} onClick={invokeOpenEditAndUpdate}/>   
      <StatusIndicator label = "Data Extracted" status={dataExtracted}/>
      <Dropdown 
        label="Data Validation" 
        placeholder="Browser" 
        value={dataValidation} 
      />      
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px; // Space between fields
  margin: 30px;
`;

const FormRow = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  margin-top: 0px;
  justify-content: flex-end; /* Aligns items to the right */
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center; /* Centers items in column mode */
  }
`;

export default DataExtractionPage;
