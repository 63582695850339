import React, { useState } from 'react';
import styled from 'styled-components';
import Modal from './Modal'; // Import the modal component

interface TermsAndConditionsProps {
  setIsTermsAccepted: React.Dispatch<React.SetStateAction<boolean>>;
}

const TermsAndConditions: React.FC<TermsAndConditionsProps> = ({ setIsTermsAccepted }) => {
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [isChecked, setIsChecked] = useState(false); // State to manage checkbox status

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleAcceptTerms = () => {
    setIsChecked(true); // Set the checkbox as accepted
    setIsTermsAccepted(true); // Call the parent function to update the state
  };

  return (
    <>
      <TermsContainer>
        <Checkbox 
          type="checkbox" 
          id="termsAccept" 
          checked={isChecked} // Use isChecked state here
          onChange={() => {}} // No action needed, managed by the modal
        />
        <Label htmlFor="termsAccept">
          I Accept the <TermsLink onClick={handleOpenModal}>Terms and Conditions</TermsLink>
        </Label>
      </TermsContainer>
      <Modal isOpen={isModalOpen} onClose={handleCloseModal} onAccept={handleAcceptTerms} />
    </>
  );
};

const TermsContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 55px;
  margin-top: 12px;
  gap: 9px;
  font-family: Unbounded, sans-serif;
`;

const Checkbox = styled.input`
  width: 20px;
  height: 17px;
  border-radius: 8px;
  cursor: pointer;
`;

const Label = styled.label`
  font-family: Poppins, sans-serif;
`;

const TermsLink = styled.span`
  text-decoration: underline;
  color: #292929;
  cursor: pointer;
`;

export default TermsAndConditions;
