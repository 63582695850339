import React, { useState } from 'react';
import { Stack, TextField, Label } from '@fluentui/react';
import styled from 'styled-components';

const FireFightingMeasuresSection: React.FC = () => {
  const [extinguishingMedia, setExtinguishingMedia] = useState(
    "suitable extinguishing media- Water spray jet Foam\nunsuitable extinguishing media- High volume water jet Carbon dioxide CO2 Dry powder"
  );

  const [specificHazards, setSpecificHazards] = useState(
    "specific hazards from chemical during firefighting- Carbon monoxide Carbon dioxide NOx Hydrogen chloride Hydrogen cyanide"
  );

  const [protectiveEquipment, setProtectiveEquipment] = useState(
    "Special protective equipment and precautions for fire-fighters- NIOSH approved self-contained breathing apparatus full protective clothing"
  );

  return (
    <Stack tokens={{ childrenGap: 3 }}>
      <SectionHeader>
        <SectionIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4de6a32e7c5c824224545ca24ff74a7e0dc3a4d20856b27b6abb547f0aec8f9?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" alt="Icon" />
        <SectionTitle>FIRE FIGHTING MEASURES</SectionTitle>
      </SectionHeader>
      <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { width: '100%' } }}>
        <LabeledInput 
          label="Extinguishing Media" 
          value={extinguishingMedia} 
          onChange={setExtinguishingMedia} 
        />
        <LabeledInput 
          label="Specific Hazards" 
          value={specificHazards} 
          onChange={setSpecificHazards} 
        />
      </Stack>
      <LabeledInput 
        label="Special Protective Equipment" 
        value={protectiveEquipment} 
        onChange={setProtectiveEquipment} 
      />
    </Stack>
  );
};

const LabeledInput = ({ label, value, onChange }: { label: string; value: string; onChange: (value: string) => void }) => (
  <InputContainer>
    <Label>{label}</Label>
    <TextField 
      styles={textFieldStyles} 
      value={value} 
      onChange={(e, newValue) => onChange(newValue || '')} // Handle input changes
    />
  </InputContainer>
);

const textFieldStyles = {
  fieldGroup: { borderRadius: 8, border: '1px solid #4952a3', height: '40px' },
  field: { padding: '5px 10px', fontSize: 14 },
};

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #4952a3;
`;

const SectionIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const SectionTitle = styled.h2`
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
`;

const InputContainer = styled.div`
  flex-grow: 1; /* Allow the input container to take the remaining width */
  width: 100%; /* Ensure full width */
`;

export default FireFightingMeasuresSection;
