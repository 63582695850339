import React from 'react';
import styled from 'styled-components';
import ProgressSteps from './ProcessStep';
import FormField from './FormField';
import AttachmentInfo from './AttachmentInfo';
import DatePicker from './DatePicker';
import Dropdown from './Dropdown';


interface SdsDownloadPageProps {
  // Add any props if needed
}

const VerifySDSPage: React.FC<SdsDownloadPageProps> = () => {
  return (
    <PageWrapper>
      <SideContent>
        <TeamImage />
        
        <DownloadMessage>
          <h1> Verify SDS</h1>
          <Accent />
          Verify your SDS to confirm all details are correct and ready for use!
        </DownloadMessage>
      </SideContent>
      <MainContent>

        <FormContainer>
          <ProgressSteps />
          <AttachmentInfo fileName="SDS1.pdf" />
          <FormRow>
          <DatePicker label="Revised Date" />
          <Dropdown label="Comaprision" options={['Same', 'Different']} />
          </FormRow>
          <FormField label="Language" placeholder="Enter the details" />
          <FormField label="Version" placeholder="Enter the details" />
          <FormField label="Manufaucturer" placeholder="Browse" isFileInput />
          <SubmitButton>Next</SubmitButton>
        </FormContainer>
      </MainContent>
    </PageWrapper>
  );
};

const PageWrapper = styled.div`
  background-color: #4952a3;
  padding: 10px;
  overflow: hidden;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const SideContent = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  width: 30%;
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 40px;
  }
`;

const TeamImage = styled.div`
  height: 500px;
  width: 100%;
  background-image: url('https://s3-alpha-sig.figma.com/img/5893/2ad0/e739204ca71badb991a8196c2d28443f?Expires=1730073600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=G1Ph9-nwZpQ4epcGQ0S1vPwDZ71RBVWOnMYsggk6xa0AMdj13GEa96RIifONHlxVy3I466z9p4pWA6HINqm5soJwK7a71QoA8RRT6uZf~LtuSX190yfApESDqYn8sZICPeyoeFqoIzD4fCzbimcTM1fRaDn0Sb-9kNU06OerCca0jaQzOk9sFeWJfh-CIDjLBg7TF4cuVjJxIVZwTSAO2E9F03tICornrKt22Y3S1bVOuMDvtud7D9Po5wqnXu5W9dMnB5OyYW5MFLv796Y8doFRHzpWs~MvqhWri4kfRa~YQ7j9vqQrWZyxmTxlLCbrS6EVQpadHM3v-Tlt~DtgxQ__');
  background-size: cover; /* Ensures the background covers the entire area */
  background-repeat: no-repeat; /* Prevents the background from repeating */
  background-position: center; /* Centers the background image */
  @media (max-width: 991px) {
    max-width: 50%;
    margin: 20px 0;
  }
`;

const Accent = styled.div`
  width: 50%; /* Keep the desired width */
  height: 4px;
  background-color: #F24C4C;
  margin: 20px 0; /* Use vertical margin only to center it vertically */
  margin-left: 100px; /* Reset left margin to align it to the left */
`;

const DownloadMessage = styled.div`
  font-size: 16px; /* Reduced font size */
  text-align: center; /* Center-align text */
  margin-top: -10px; /* Reduced margin */
`;

const MainContent = styled.main`
  width: 70%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

const FormContainer = styled.form`
  border-radius: 8px;
  background-color: #fff;
  padding: 88px 77px;
  border: 1px solid #fff;
  @media (max-width: 991px) {
    padding: 40px 20px;
  }
`;

const FormRow = styled.div`
  display: flex;
  gap: 40px;
  margin-top: 40px;
  @media (max-width: 991px) {
    flex-direction: column;
  }
`;

const SubmitButton = styled.button`
  border-radius: 8px;
  background-color: #d12031;
  color: #fff;
  padding: 15px 24px;
  font: 700 16px Poppins, sans-serif;
  border: 1px solid #fff;
  cursor: pointer;
  margin-top: 39px;
`;

export default VerifySDSPage;