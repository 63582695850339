import React, { useState } from 'react';
import { Stack, TextField, Label } from '@fluentui/react';
import styled from 'styled-components';

const HandlingStorage: React.FC = () => {
  const [precautions, setPrecautions] = useState(
    "Advice on safe handling - Avoid dust formation. Keep away from sources of ignition. Lead off electrostatic charges. Avoid inhalation, ingestion, and contact with skin and eyes. Wash thoroughly after handling.\n\nHandling personnel - None specified\n\nPhysical handling aspects - Keep away from heat and sources of ignition."
  );
  const [conditions, setConditions] = useState('NA');

  return (
    <Stack tokens={{ childrenGap: 3 }}>
      <SectionHeader>
        <SectionIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4de6a32e7c5c824224545ca24ff74a7e0dc3a4d20856b27b6abb547f0aec8f9?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" alt="Icon" />
        <SectionTitle>HANDLING STORAGE</SectionTitle>
      </SectionHeader>
      <LabeledInput label="Precautions for Safe Handling" value={precautions} onChange={setPrecautions} />
      <LabeledInput label="Condition for Safe Storage" value={conditions} onChange={setConditions} />
    </Stack>
  );
};

const LabeledInput = ({ label, value, onChange }: { label: string; value?: string; onChange: (value: string) => void }) => (
  <InputContainer>
    <Label>{label}</Label>
    <TextField 
      styles={textFieldStyles} 
      value={value} 
      onChange={(e, newValue) => onChange(newValue || '')} 
      multiline 
      rows={3 
      } 
    />
  </InputContainer>
);

const textFieldStyles = {
  fieldGroup: { borderRadius: 8, border: '1px solid #4952a3', height: 'auto' },
  field: { padding: '5px 10px', fontSize: 14 },
};

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #4952a3;
`;

const SectionIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const SectionTitle = styled.h2`
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
`;

const InputContainer = styled.div`
  flex-grow: 1; /* Allow the input container to take the remaining width */
  width: 100%; /* Ensure full width */
`;

export default HandlingStorage;
