import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

interface DropdownProps {
  label: string;
  placeholder: string;
  value: string
}

const Dropdown: React.FC<DropdownProps> = ({ label, placeholder, value }) => {

  return (
    <DropdownWrapper>
      <Label htmlFor={label}>{label}</Label>
      <SelectWrapper>
        <Select id={label}>
          <option value={value} disabled selected hidden>{value}</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </Select>
        <ArrowIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/7fb21c3d34ab4c16ee05132c961dae07293f656b9d43829eb55c23729296d2ea?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" alt="Dropdown arrow" />
      </SelectWrapper>
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const Label = styled.label`
  color: #11142d;
  font-weight: 700;
  margin-bottom: 8px;
  font-size: 16px; // Use font-size instead of font
  line-height: 1.2; // Use line-height separately
  font-family: 'Poppins', sans-serif; // Specify the font-family explicitly
`;

const SelectWrapper = styled.div`
  position: relative;
`;

const Select = styled.select`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #5541d7;
  background-color: #fff;
  padding: 18px 24px;
  font: 600 16px Poppins, sans-serif;
  color: #7a7a8a;
  appearance: none;
`;

const ArrowIcon = styled.img`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  pointer-events: none;
`;

export default Dropdown;