import axiosUserService from "./axiosUserService";

const axiosUserBaseQuery =  () =>
	async ({ url, method, body, params, headers, responseType} :any ) => {
		try {
			const result = await axiosUserService({
				url: url,
				method,
				data: body,
				params,
				headers,
				responseType: responseType === undefined ? 'json' : responseType
			})
			console.log("result ", result);
			return {data: result};
		} catch (axiosError) {
			console.log("axiosError ", axiosError);
			const err:any = axiosError;
			return { error : {
				status: err.response?.status,
				message: err.response?.data || err.message
				}
			};
		}
	}

export default axiosUserBaseQuery;