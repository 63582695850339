import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "./axiosBaseQuery";
export const SDSRequestsAPI = createApi({
  reducerPath: "SDSRequestsAPI",
  baseQuery: axiosBaseQuery(),
  tagTypes: ["user"],
  endpoints: (builder) => ({
    fetchAllSDSRequests: builder.mutation({
      query: (body) => {
        return {
          url: "http://localhost:8080/api/sds-requests/search",
          method: "POST",
          body,
        };
      },
    })

  }),
});
export const {
  useFetchAllSDSRequestsMutation
} = SDSRequestsAPI;
