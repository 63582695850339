import React from 'react';
import styled from 'styled-components';

const InfoSection: React.FC = () => {
  return (
    <InfoWrapper>
      <InfoContent>
        <IllustrationImage
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/92639b557cb6fd12bc775ca379a5cb6683811bbc0129a75ab3e5bfc68c5b7e10?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa"
          alt="Ticket submission illustration"
        />
        <Separator />
        <InfoText>
          Got an issue? Submit a ticket and upload your file for a quicker
          resolution!
        </InfoText>
      </InfoContent>
    </InfoWrapper>
  );
};

const InfoWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  background-color: #4952a3;
  padding: 40px;
  @media (max-width: 991px) {
    width: 100%;
    padding: 20px;
  }
`;

const InfoContent = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: #fff;
  font: 500 16px Poppins, sans-serif;
`;

const IllustrationImage = styled.img`
  aspect-ratio: 0.74;
  object-fit: contain;
  object-position: center;
  width: 100%;
`;

const Separator = styled.div`
  background-color: #f24c4c;
  width: 174px;
  height: 4px;
  margin: 20px auto;
`;

const InfoText = styled.p`
  margin-top: 20px;
  font-size: 14px;
`;

export default InfoSection;
