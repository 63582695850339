import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const ForgotPasswordForm: React.FC = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const [username, setUsername] = useState(''); // State to hold the username/email
  const [error, setError] = useState(''); // State to hold error messages

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Email validation logic using a regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex to validate email format

    if (!username) {
      setError('Please enter your username or email.'); // Check if input is empty
    } else if (!emailPattern.test(username)) {
      setError('Please enter a valid email address.'); // Check if input matches email pattern
    } else {
      setError(''); // Clear any previous errors
      // Redirect to the reset password page if validation is successful
      navigate('/reset-password');
    }
  };

  return (
    <FormWrapper>
      <FormTitle>Forgot Password</FormTitle>
      <FormDescription>
        Enter the email address you used when you joined and we'll send you instructions to reset your password.
        <br /><br />
        For security reasons, we do NOT store your password. So rest assured that we will never send your password via email.
      </FormDescription>
      <form onSubmit={handleSubmit}> {/* Bind the form submission to handleSubmit */}
        <InputGroup>
          <Label htmlFor="username">Username</Label>
          <Input
            type="text"
            id="username"
            placeholder="Enter your username"
            aria-label="Enter your username"
            value={username} // Controlled input
            onChange={(e) => setUsername(e.target.value)} // Update state on input change
          />
          {error && <ErrorMessage>{error}</ErrorMessage>} {/* Display error message */}
        </InputGroup>
        <SubmitButton type="submit">Continue</SubmitButton>
      </form>
    </FormWrapper>
  );
};

// Styled Components

const FormWrapper = styled.section`
  display: flex;
  width: 68%;
  padding: 25px;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-left: 20px;
    margin-top: 40px;
  }
`;

const FormTitle = styled.h2`
  color: var(--Light-Text-Color-Title, #11142d);
  letter-spacing: 0.15px;
  align-self: flex-start;
  font: 700 31px Poppins, sans-serif;
  margin: 0px;
`;

const FormDescription = styled.p`
  color: var(--Light-Text-Color-Body-1, #92929d);
  letter-spacing: 0.07px;
  margin-top: 16px;
  font: 400 15px/20px Poppins, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const InputGroup = styled.div`
  display: flex;
  margin-top: 62px;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Label = styled.label`
  font-family: Poppins, sans-serif;
  color: var(--Light-Text-Color-Title, #11142d);
  font-weight: 700;
  padding: 0 16px;
`;

const Input = styled.input`
  border-radius: 8px;
  border: 1px solid var(--light-primary-color-50, #5541d7);
  background: var(--Light-Fills-Color-Text-Field, #fff);
  margin-top: 8px;
  font-family: Mulish, sans-serif;
  color: var(--Light-Text-Color-Body-2, #9a9ab0);
  font-weight: 400;
  padding: 18px 24px 19px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding: 18px 20px;
  }
`;

const SubmitButton = styled.button`
  border-radius: 8px;
  background-color: rgba(209, 32, 49, 1);
  margin-top: 48px;
  min-height: 48px;
  font-family: Poppins, sans-serif;
  color: var(--Light-Text-Link-Color-White, #fff);
  font-weight: 700;
  text-align: center;
  padding: 15px 24px;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 15px 20px;
  }
`;

const ErrorMessage = styled.div`
  color: red; // Set the color for the error message
  margin-top: 8px; // Space between input and error message
  font-size: 14px; // Adjust font size for visibility
`;

export default ForgotPasswordForm;