import React from 'react';
import styled from 'styled-components';

interface ButtonProps {
  children: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void; 
  page: string
}

const Button: React.FC<ButtonProps> = ({ children, onClick, page }) => {
  return <StyledButton onClick={onClick} page={page}>{children}</StyledButton>;
};

const StyledButton = styled.button<{ page: string }>`
  border-radius: 8px;
    margin-top:10px;
    margin-left: 600px;
    background-color: ${(props) => (props.page === "edit" ? "#4952a3" : "#d12031")};
    color: #fff;
    padding: 10px 20px;
    font: 700 16px / 1.2 Poppins, sans-serif;
    border: 1px solid #fff;
    cursor: pointer;
    transition: background-color 0.3s ease`;

export default Button;