import React, { useState, ChangeEvent, FormEvent } from 'react';
import styled from 'styled-components';
import PasswordRequirements from './PasswordRequirements';
import PasswordInput from './PasswordInput';

const ResetPassword: React.FC = () => {
  const passwordRequirements = [
    'Must be at least 15 characters long',
    'Must contain an uppercase letter',
    'Must contain a lowercase letter',
    'Must contain a number',
    'Must contain a special character (!, @, #, $..)',
  ];

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [validations, setValidations] = useState<boolean[]>(new Array(passwordRequirements.length).fill(false));
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false); // State for showing password
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false); // State for showing confirm password

  // Function to validate password against requirements
  const validatePassword = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    const newValidations = [
      input.length >= 15,
      /[A-Z]/.test(input),
      /[a-z]/.test(input),
      /[0-9]/.test(input),
      /[!@#$%^&*]/.test(input),
    ];
    setValidations(newValidations);
    setPassword(input);
    setErrorMessage('');
  };

  const handleConfirmPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setConfirmPassword(input);
    if (input === password) {
      setErrorMessage('');
    } else {
      setErrorMessage('Passwords do not match!');
    }
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validations.every(valid => valid) && password === confirmPassword) {
      console.log("Password reset successfully!");
      setPassword('');
      setConfirmPassword('');
      setErrorMessage('');
    } else if (!validations.every(valid => valid)) {
      setErrorMessage('Please meet all password requirements.');
    } else {
      setErrorMessage('Passwords do not match!');
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword); // Toggle visibility for password
  };

  const toggleShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword); // Toggle visibility for confirm password
  };

  return (
    <ResetPasswordContainer>
      <ContentWrapper>
        <ImageColumn>
          <ImageContent>
            <Logo
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f2a4c0998a3f35b9f5b50d8d7429b9623fda4d4f3e97642930e474acbddcd0f7?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa"
              alt="Company Logo"
            />
            <Underline />
            <Tagline>
              New password, new start. Reset now and keep <br /> moving forward!
            </Tagline>
          </ImageContent>
        </ImageColumn>
        <FormColumn>
          <FormContainer>
            <h1 className="form-title">Reset Password</h1>
            <p className="form-description">
              Enter the new password to reset the password on your account.{' '}
              <br /> We'll ask for this password whenever you login.{' '}
            </p>
            <form className='form_container' onSubmit={handleSubmit}>
              <PasswordInput
                label="New Password"
                placeholder="Input the details here"
                required
                onChange={validatePassword}
                showPassword={showPassword} // Pass the visibility state
                toggleShowPassword={toggleShowPassword} // Pass toggle function
              />
              <PasswordRequirementList>
                {passwordRequirements.map((requirement, index) => (
                  <PasswordRequirements key={index} text={requirement} isValid={validations[index]} />
                ))}
              </PasswordRequirementList>
              <PasswordInput
                label="Confirm Password"
                placeholder="Input the details here"
                required
                onChange={handleConfirmPasswordChange}
                showPassword={showConfirmPassword} // Pass the visibility state for confirm password
                toggleShowPassword={toggleShowConfirmPassword} // Pass toggle function
              />
              {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
              <SubmitButton type="submit" disabled={!!errorMessage || !validations.every(valid => valid)}>
                Reset Password
              </SubmitButton>
            </form>
          </FormContainer>
        </FormColumn>
      </ContentWrapper>
    </ResetPasswordContainer>
  );
};

// Styled components remain unchanged...

const ResetPasswordContainer = styled.main`
  width: 96%;
  height: 100%;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  height: 100vh;
  width: 105%;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const ImageColumn = styled.section`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 50%;
  margin-top: -70px;
  background-color: #4952A3;
  margin-left: -22px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ImageContent = styled.div`
  display: flex;
  padding-bottom: 6px;
  flex-direction: column;
  align-items: center;
  color: #fff;
  text-align: center;
  font: 500 16px Poppins, sans-serif;
  margin-top: 10%;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const Logo = styled.img`
  width: 65%;
  height: 80%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const Underline = styled.div`
  background-color: #f24c4c;
  width: 174px;
  height: 5px;
  margin-top: 5px;
`;

const Tagline = styled.p`
  background-blend-mode: normal;
  margin-top: 18px;
`;

const FormColumn = styled.section`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 60%;
  margin: 33px 0px 0px 30px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const FormContainer = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 24px 59px;
  font-size: 14px;
  letter-spacing: 0.08px;
  line-height: 4.2;
  height: 100vh;
  width: 99%;
  margin-left: -30px;
  margin-top: -52px;

  .form-title {
    color: #000;
    letter-spacing: 0.15px;
    font: 700 31px Poppins, sans-serif;
    margin: 0px;
  }

  .form-description {
    color: #000;
    letter-spacing: 0.07px;
    font: 300 15px/30px Poppins, sans-serif;
    padding: 12px;

    @media (max-width: 991px) {
      max-width: 100%;
    }
  }
`;

const PasswordRequirementList = styled.ul`
  list-style-type: circle;
  padding: 0;
  margin: 20px;
`;

const SubmitButton = styled.button`
  border-radius: 8px;
  background-color: #d12031;
  margin-top: 14px;
  font-family: Poppins, sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding: 17px 24px;
  border: none;
  cursor: pointer;
  margin-bottom: 10%;
  @media (max-width: 991px) {
    padding: 17px 20px;
  }
`;

const ErrorMessage = styled.div`
  color: #d12031;
  margin-top: 10px;
  font-size: 14px;
`;

export default ResetPassword;
