import React from 'react';
import styled from 'styled-components';

interface AttachmentInfoProps {
  fileName: string;
}

const AttachmentInfo: React.FC<AttachmentInfoProps> = ({ fileName }) => {
  return (
    <AttachmentWrapper>
      <AttachmentLabel>Attachment:</AttachmentLabel>
      <FileName>{fileName}</FileName>
      <AttachmentIcon />
    </AttachmentWrapper>
  );
};

const AttachmentWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
  font: 16px/1.2 Poppins, sans-serif;
  letter-spacing: 0.08px;
`;

const AttachmentLabel = styled.span`
  color: #11142d;
  font-weight: 700;
`;

const FileName = styled.span`
  color: #9a9ab0;
`;

const AttachmentIcon = styled.div`
  width: 16px;
  height: 16px;
  background-image: url('path-to-your-attachment-icon.svg'); /* Ensure this path is correct */
  background-size: contain;
  background-repeat: no-repeat;
`;

export default AttachmentInfo;
