import React from "react";
import styled from "styled-components";
import { Stack } from "@fluentui/react";
import { current } from "@reduxjs/toolkit";

interface StepProps {
  step: number;
  label: string;
  isActive: boolean;
  isCompleted: boolean;
  currentStep: number;
  completedSteps: number[];
}

interface ProgressStepsProps {
  currentStep: number;
  completedSteps: number[];
}

const ProgressSteps: React.FC<ProgressStepsProps> = ({
  currentStep,
  completedSteps,
}) => {
  const steps: StepProps[] = [
    {
      step: 1,
      label: "Download SDS",
      isActive: currentStep === 1,
      isCompleted: completedSteps.includes(1),
      currentStep: currentStep,
      completedSteps: completedSteps
    },
    {
      step: 2,
      label: "Verify SDS",
      isActive: currentStep === 2,
      isCompleted: completedSteps.includes(2),
      currentStep: currentStep,
      completedSteps: completedSteps
    },
    {
      step: 3,
      label: "Data Extraction",
      isActive: currentStep === 3,
      isCompleted: completedSteps.includes(3),
      currentStep: currentStep,
      completedSteps: completedSteps
    },
    {
      step: 4,
      label: "Data Validation",
      isActive: currentStep === 4,
      isCompleted: completedSteps.includes(4),
      currentStep: currentStep,
      completedSteps: completedSteps
    },
    {
      step: 5,
      label: "Submit",
      isActive: currentStep === 5,
      isCompleted: completedSteps.includes(5),
      currentStep: currentStep,
      completedSteps: completedSteps
    },
  ];

  return (
    <>
      <StepsContainer>
        {steps.map((step, index) => (
          <Stack>
            <Stack style={{ display: "flex" }}>
              <ActiveIndicator step={step.step} currentStep={currentStep}>{step.step === currentStep ? "In progress" : step.step < currentStep ? <CompletedIndicator step={step.step} currentStep={currentStep}>{"Completed"}</CompletedIndicator> : ""}</ActiveIndicator>
              {/* {step.step === currentStep ? (
                <ActiveIndicator>In progress</ActiveIndicator>
              ) : step.step < currentStep ? <CompletedIndicator>Completed</CompletedIndicator>
                : " "
            } */}
              <StepTitle step={step.step} currentStep={currentStep}>
                STEP {step.step}
              </StepTitle>
            </Stack>
            <Stack
              horizontal
              tokens={{ childrenGap: 20 }}
            >
              <Step
                key={step.step}
                {...step}
                isLast={index === steps.length - 1}
                currentStep={currentStep}
                completedSteps= {completedSteps}
              />
            </Stack>
          </Stack>
        ))}
      </StepsContainer>
    </>
  );
};

const Step: React.FC<StepProps & { isLast: boolean }> = ({
  step,
  label,
  isActive,
  isCompleted,
  isLast,
  currentStep,
  completedSteps
}) => (
  <StepWrapper>
    <StepNumber isActive={isActive} isCompleted={isCompleted}>
    </StepNumber>
    {!isLast && (
      <StepConnector isActive={isActive || isCompleted} step={step} currentStep={currentStep} completedSteps={completedSteps}/>
    )}
    <StepLabel>{label}</StepLabel>
  </StepWrapper>
);


const StepsContainer = styled.div`
  display: flex;
  width: 98%; /* Set this to your desired width */
  /* Example max width */
  justify-content: space-between;
  margin-bottom: 25px;
  
  }
`;

const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const StepTitle = styled.div<{ step: number; currentStep: number }>`
  color: #11142d;
  font-weight: 700;
  margin-bottom: 5px;
  margin-left: ${(props) =>
    props.step === 1
      ? "25px"
      : props.step === 3
      ? "36px"
      : props.step === 2 || props.step === 5
      ? "13px"
      : "26px"};
  margin-top:${(props) => (props.step === props.currentStep || props.step < props.currentStep ? "10px" : "25px")}; 
  font-size: 16px; // Use font-size instead of font
  line-height: 1.2; // Use line-height separately
  font-family: "Poppins", sans-serif; // Specify the font-family explicitly
`;

const StepNumber = styled.div<{ isActive: boolean; isCompleted: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  background-color: #fff;
  color: #fff;
  border: 3px solid ${(props) => (props.isCompleted ? "green" : "#4952a3")}; // Green border if completed
  position: relative;

  // Inner tick style
  &::after {
    content: ${props => (props.isCompleted ? '"✓"' : '')}; // Display tick if completed
    color: green; // Color of the tick
    font-size: 10px; // Adjust the font size of the tick as needed
    transform: translate(0%);
  }

  // Adjust inner dot style
  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${(props) => (props.isActive ? "#4952a3" : props.isCompleted ? "none" :"#fff")};
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const StepConnector = styled.div<{ isActive: boolean; step: number; currentStep: number; completedSteps: number[]; }>`
  width: ${(props) =>
    props.step === 1 && props.isActive 
      ? "130px"
      : props.step === 3 && props.isActive ? "147px" 
      : props.step === 3 || (props.step === 2 && props.isActive)
      ? "147px"
      : props.step === 4
      ? "118px"
      : "135px"};
  
  height: 2px;
  
  background: ${(props) => 
    props.currentStep > props.step 
      ? "#4952a3" // Full blue if completed
      : props.isActive && props.currentStep > props.step 
      ? "linear-gradient(to right, #4952a3 50%, #e0e0e0 50%)" // Half blue, half grey if active and previous is completed
      : "#e0e0e0"}; // Grey if not active or completed

  position: absolute;
  top: ${(props) => (props.isActive ? "10px" : "12px")};
  left: ${(props) =>
    props.isActive && props.step !== 2 && props.step !== 3
      ? "62%"
      : props.step === 3 || props.step === 1
      ? "60%"
      : props.step === 4
      ? "60%"
      : "62%"};
  
  @media (max-width: 991px) {
    display: none;
  }
`;

// const StepConnector = styled.div<{ isActive: boolean; step: number; currentStep: number; }>`
//   width: ${(props) =>
//     props.step === 1 && props.isActive 
//       ? "130px"
//       : props.step === 3 && props.isActive ? "147px" 
//       : props.step === 3 || (props.step === 2 && props.isActive)
//       ? "147px"
//       : props.step === 4
//       ? "118px"
//       : "135px"};
//   height: 2px;
//   background: ${(props) => 
//     props.currentStep > props.step 
//       ? "#4952a3" // Full blue if completed
//       : props.isActive 
//       ? "linear-gradient(to right, #4952a3 50%, #e0e0e0 50%)" // Half blue, half grey if active
//       : "#e0e0e0"}; // Grey if not active or completed
//   position: absolute;
//   top: ${(props) => (props.isActive ? "10px" : "12px")};
//   left: ${(props) =>
//     props.isActive && props.step != 2 && props.step != 3
//       ? "62%"
//       : props.step === 3 || props.step === 1
//       ? "60%"
//       : props.step === 4
//       ? "60%"
//       : "60%"};  
//   @media (max-width: 991px) {
//     display: none;
//   }
// `;



const StepLabel = styled.div`
  margin-top: 8px;
  font-size: 20px;
  color: #11142d;
  text-align: center;
  font: 400 14px/1.2 Poppins, sans-serif;
`;

const ActiveIndicator = styled.div<{ step: number; currentStep: number }>`
  color: #d12031;
  margin-left:${(props) => (props.step === 2 ? "5px" : props.step===3? "24px": props.step===5? "5px": "20px")};
  font: italic 400 14px/1.2 'Poppins', sans-serif; /* Move 'italic' to the beginning */
`;

const CompletedIndicator = styled.div<{ step: number; currentStep: number }>`
  color: #4952A3;
  font: italic 400 14px/1.2 'Poppins', sans-serif; /* Move 'italic' to the beginning */
`;


export default ProgressSteps;
