import React from 'react';
import { Stack } from '@fluentui/react';
import HelpCard from './HelpCard';
import questionIcon from '../../assets/image/question-icon.png';

const cardData = [
  {
    iconSrc: questionIcon,
    title: "SDS Update",
    description: "How many SDS are currently outdated?",
    learnMoreLink: "#"
  },
  {
    iconSrc: questionIcon,
    title: "SDS Update",
    description: "Which supplier has the most SDS that are outdated?",
    learnMoreLink: "#"
  },
  {
    iconSrc: questionIcon,
    title: "SDS Update",
    description: "For product name, what has changed from previous to current SDS version?",
    learnMoreLink: "#"
  },
  {
    iconSrc: questionIcon,
    title: "SDS Update",
    description: "Provide the number of SDS based on the region.",
    learnMoreLink: "#"
  },
  {
    iconSrc: questionIcon,
    title: "SDS Update",
    description: "How many SDS are related to regulation (EC) n. 1907/2006(REACH)?",
    learnMoreLink: "#"
  },
  {
    iconSrc: questionIcon,
    title: "SDS Update",
    description: "How many SDS does not contain CAS or REACH numbers?",
    learnMoreLink: "#"
  },
];

interface CardGridProps {
  onLearnMoreClick: (title: string, description: string, answer: string) => void; // Update the prop type
}

const CardGrid: React.FC<CardGridProps> = ({ onLearnMoreClick }) => {
  const answers = [
    "There are currently 10 SDS that are outdated.",
    "Supplier XYZ has the most outdated SDS.",
    "The changes from previous to current SDS version for Product ABC include...",
    "The number of SDS based on region is as follows: Region A - 5, Region B - 3.",
    "There are 15 SDS related to regulation (EC) n. 1907/2006 (REACH).",
    "There are 2 SDS that do not contain CAS or REACH numbers."
  ];

  return (
    <Stack
      horizontal
      wrap
      tokens={{ childrenGap: 12 }}
      styles={{ root: { margin: '17px 0 0 0', width: 'calc(100%)', height: '65vh' } }}
    >
      {cardData.map((card, index) => (
        <Stack.Item
          key={index}
          grow
          styles={{ root: { width: 'calc(33.33% - 12px)', height: 'auto', flexGrow: 1 } }}
        >
          <HelpCard 
            {...card} 
            onLearnMoreClick={() => onLearnMoreClick(card.title, card.description, answers[index])} // Pass answer
          />
        </Stack.Item>
      ))}
    </Stack>
  );
};

export default CardGrid;
