import React from 'react';
import { Dialog, DialogType, TextField, Stack, Text, DefaultButton } from '@fluentui/react';
import { CSSTransition } from 'react-transition-group'; 
import { Icon } from '@fluentui/react/lib/Icon'; // Import Fluent UI Icon
import './ChatPopup.css'; 

interface ChatPopupProps {
  isOpen: boolean;
  onClose: () => void;
  description?: string; 
  answer?: string; // Add answer prop
}

const ChatPopup: React.FC<ChatPopupProps> = ({ isOpen, onClose, description, answer }) => {
  return (
    <CSSTransition
      in={isOpen}
      timeout={300}
      classNames="dialog"
      unmountOnExit
    >
      <Dialog
        hidden={!isOpen}
        onDismiss={onClose}
        dialogContentProps={{
          type: DialogType.normal,
          styles: { title: { color: 'white' } },
        }}
        styles={{
          main: {
            width: '40%', // Adjust width here (e.g., 40% of the viewport)
            minWidth: '500px', 
            borderRadius: '8px',
            backgroundColor: '#4952A3',
            height: '80vh',
            marginLeft: '900px',
            marginTop: '60px',
          },
        }}
      >
        <Stack styles={{ root: { padding: '10px', height: '100%', marginTop:'24px', justifyContent: 'space-between' } }}>
          {/* Close Icon */}
          <Icon 
            iconName="Cancel" // Fluent UI icon name
            styles={{ root: { color: 'white', cursor: 'pointer', position: 'absolute', top: '-42px', right: '-3px' } }}
            onClick={onClose} // Call onClose when clicked
          />
          
          {/* Description Text */}
          <Text variant="medium" styles={{ root: { color: '#fff' } }}>
            {description}
          </Text>

          {/* Display the answer in a disabled text box */}
          {answer && (
            <TextField
              value={answer} // Set the value to the answer
              disabled // Make the text box non-editable
              multiline
              rows={5} // Fixed number of rows
              styles={{
                root: { marginTop: '20px' },
                fieldGroup: {
                  border: 'none',
                  marginBottom: '0px',
                  borderRadius: '20px',
                  backgroundColor: '#f0f0f0',
                  padding: '20px',
                },
                field: {
                  height: 'auto',
                  color: '#000',
                  resize: 'none', // Prevent resizing
                },
              }}
            />
          )}

          {/* Done Button */}
          <DefaultButton onClick={onClose} text="Done" styles={{ root: { marginTop: '20px', borderRadius: '8px' } }} />
        </Stack>
      </Dialog>
    </CSSTransition>
  );
};

export default ChatPopup;
