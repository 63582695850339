import { Combobox, Field, Input, Option } from "@fluentui/react-components";
import React, { useState } from "react";
import { EyeRegular, EyeOffRegular } from '@fluentui/react-icons';
import styled from "styled-components";
import { TextField } from "@fluentui/react";
import { Eye, EyeOff } from "react-feather";

export const FormInput: React.FunctionComponent = ({ field, form, ...props} : any) => {
  const { label, placeholder } = props;
  return <InputGroup>
          <StyledTextField
            {...field} {...props}
            placeholder={placeholder}
            label={label}
          />
        </InputGroup>
}

export const PasswordInput = ({ field, form, ...props} : any) => {
  const [ isPassword, setIspassword ] = useState<boolean>(true);
  const { label, placeholder} = props;
  return <InputGroup>
        <PasswordContainer>
          <StyledTextField
            {...props} {...field}
            type={isPassword ? 'password' : 'text'} 
            placeholder={placeholder}
          />
          <PasswordToggle onClick={() => setIspassword((prev) => !prev)} type="button">
            {isPassword ? <Eye size={20} /> : <EyeOff size={20} />}
          </PasswordToggle>
        </PasswordContainer>
      </InputGroup>
}

export const FormError = ({ ...props } : any) => {
  const { children } = props;
  return <div style={{ fontWeight: 'bold', color:'red', display: 'flex', alignSelf: 'center'}}>{children}</div>
}

export const DropdownInput = ({ field, form, ...props} : any) => {
  const { label, orientation, options, size } = props;
  const onChange = (option: any, data: any) => {
    console.log(data);
    const value = options.filter((v: any) => data.selectedOptions.includes(v.id));
    form.setFieldValue(
      field.name,
      value
    );
  };
  return (
    <Field required label={label} style={{ marginTop: 5}} orientation={orientation}
      size={size}
    >
      <Combobox 
        multiselect={true}
        onOptionSelect={onChange}
        size={size}
        value={field.value !== undefined ? field.value.map((v: any) => v.name).join(", ") : ""}
        defaultSelectedOptions={field.value.map((v: any) => v.id)}
      >
        {options.map((option: any) => (
          <Option key={option.id} value={option.id}>
            {option.name}
          </Option>
        ))}
      </Combobox>
    </Field>
  );
}

const InputGroup = styled.div`
  margin-top: 20px;
`;

const StyledTextField = styled(TextField)`
  .ms-TextField-fieldGroup {
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid #4952a3;
    color: #4952a3;
    height: 50px;
    padding: 8px;
    font-weight: 700;
  }

  .ms-TextField-field {
    padding: 15px 0; 
    height: 100%; 
  }

  .ms-Label {
    font-weight: 700;
  }
`;

const PasswordContainer = styled.div`
  position: relative; 
`;

const PasswordToggle = styled.button`
  position: absolute;
  right: 10px; 
  top: 70%; 
  transform: translateY(-50%); 
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none; 
  }
`;
