import React, { useState } from 'react';
import styled from 'styled-components';
import SidebarItem from './SideBarItem';
import Avatar from './Avatar';
import logoSrc from '../../assets/image/Team Kamerai (11) 1.png';
import { Icon } from '@fluentui/react'; // For collapse/expand icon
import { useLocation, useNavigate } from 'react-router-dom'; // Import useNavigate

// CollapseButton definition
const CollapseButton = styled.div`
  cursor: pointer;
  align-items: center;
  font-size: small;
  font-weight: bold;
`;

const SidebarWrapper = styled.nav<{ collapsed: boolean; visible: boolean }>`
  background-color: rgba(244, 244, 244, 0.957);
  display: ${({ visible }) => (visible ? 'flex' : 'none')}; /* Control visibility */
  flex-direction: column;
  overflow: hidden;
  padding: 24px 18px 23px 18px;
  border-radius: 32px;
  width: ${(props) => (props.collapsed ? '6%' : '22%')};
  transition: width 0.3s ease;
  font-family: 'Poppins', sans-serif;
  height: 100vh;
  @media (max-width: 768px) {
    width: ${(props) => (props.collapsed ? '8%' : '25%')};
    padding: 10px 10px 10px 10px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img<{ collapsed: boolean }>`
  width: ${(props) => (props.collapsed ? '43px' : '43px')};
  height: ${(props) => (props.collapsed ? '43px' : '46px')};
  transition: width 0.3s ease, height 0.3s ease;
`;

const initialItems = [
  { icon: 'Home', text: 'Dashboard', route: '/' },
  { icon: 'DocumentSet', text: 'SDS List', route: '/document' },
  { icon: 'ComplianceAudit', text: 'Audit Logs', route: '/sdsrequest/audit-logs' }, // Assuming this route exists
  { icon: 'People', text: 'User Management', route: '/user' },
  { icon: 'Settings', text: 'Settings', route: '/settings' },
  { icon: 'Feedback', text: 'Feedback', route: '/feedback' },
  { icon: 'Help', text: 'Ask Marie', route: '/askmarie' },
];

const Sidebar: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [collapsed, setCollapsed] = useState(true);
  const location = useLocation();
  const navigate = useNavigate(); // Initialize the useNavigate hook

  // Check if the sidebar should be visible based on the current route
  const shouldHideSidebar =
    location.pathname === '/sdsrequest/download' ||
    location.pathname === '/' ||
    location.pathname === '/reset-password' ||
    location.pathname === '/forgot-password' || location.pathname.startsWith('/sdsrequest/import/');

  const handleItemClick = (index: number) => {
    setActiveIndex(index);
    const selectedItem = initialItems[index];
    if (selectedItem.route) {
      navigate(selectedItem.route); // Navigate to the selected route
    }
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  return (
    <SidebarWrapper collapsed={collapsed} visible={!shouldHideSidebar}>
      <LogoWrapper>
        <Logo src={logoSrc} alt="App Logo" collapsed={collapsed} />
        <CollapseButton onClick={toggleCollapse}>
          <Icon iconName={collapsed ? 'DoubleChevronRight' : 'DoubleChevronLeft'} />
        </CollapseButton>
      </LogoWrapper>

      {initialItems.map((item, index) => (
        <div key={index} onClick={() => handleItemClick(index)}>
          <SidebarItem
            icon={item.icon}
            text={item.text}
            active={index === activeIndex}
            collapsed={collapsed}
          />
        </div>
      ))}

      <Avatar />
    </SidebarWrapper>
  );
};

export default Sidebar;
