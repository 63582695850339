import React from 'react';
import styled from 'styled-components';

const RatingIllustration: React.FC = () => {
  return (
      <RatingIllustrationDiv>
       <SideImage src="https://cdn.builder.io/api/v1/image/assets/TEMP/9d2b7ea0c7195b658d4f8dd643a8925dc4ea0cd98c2bd745f0b0206ea8ad0e39?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" alt="Decorative Side Image" />
       </RatingIllustrationDiv>
  );
};
const RatingIllustrationDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 37%;
  background: #4952a3;
      margin-top: -15px;
    margin-left: 40px;
    height: 100vh;
    padding: 75px 15px;
 
`;

const SideImage = styled.img`
  aspect-ratio: 0.82;
  object-fit: contain;
     width: 75%;
    margin-left: 45px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;


export default RatingIllustration;