import React, { useState } from 'react';
import { Stack, Text } from '@fluentui/react';
import CardGrid from './CardGrid';
import Header from './Header';
import ChatPopup from './ChatPopup';

const HelpCenter: React.FC = () => {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [chatDescription, setChatDescription] = useState<string>(''); // State for description
  const [chatAnswer, setChatAnswer] = useState<string>(''); // State for answer

  const openChat = (title: string, description: string, answer: string) => {
    setChatDescription(description); // Set the description for the chat popup
    setChatAnswer(answer); // Set the answer for the chat popup
    setIsChatOpen(true);
  };

  const closeChat = () => {
    setIsChatOpen(false);
  };

  return (
    <div className="main-wrapper" style={{ display: 'flex', height: '100vh' }}>
      <div className="content-wrapper" style={{ width: '79%', marginTop: '-22px' }}>
        <Header />
        <div style={{ padding: '24px', backgroundColor: '#f4f4f4', width: '125%', height: '85%' , marginTop: '-23px', marginLeft: '0px' , borderRadius: '32px' }}>
          <Stack styles={{ root: { backgroundColor: '#4952A3', height: '17%', padding: '17px', marginTop: '-5px', borderRadius: '10px' } }} tokens={{ childrenGap: 20 }}>
            <Stack tokens={{ childrenGap: 15 }}>
              <Text variant="xxLarge" styles={{ root: { color: '#fff', fontWeight: 600, marginTop: '10px' } }}>
                Help Center
              </Text>
            </Stack>
          </Stack>
          <CardGrid onLearnMoreClick={openChat} />
        </div>
      </div>
      
      {/* Chat Popup */}
      <ChatPopup 
        isOpen={isChatOpen} 
        onClose={closeChat} 
        description={chatDescription} // Pass description to the popup
        answer={chatAnswer} // Pass answer to the popup
      />
    </div>
  );
};

export default HelpCenter;
