import React from 'react';
import styled from 'styled-components';
import { Persona, PersonaSize } from '@fluentui/react';

const AvatarWrapper = styled.div`
  margin-top: 62px;
`;

const Avatar: React.FC = () => {
  return (
    <AvatarWrapper>
      <Persona
        size={PersonaSize.size48}
        initialsColor="#4952A3"
        text="User Name"
        secondaryText="Test data"
      />
    </AvatarWrapper>
  );
};

export default Avatar;
