import React, { useState } from 'react';
import { Stack, TextField, Label } from '@fluentui/react';
import styled from 'styled-components';

const HazardIdentification: React.FC = () => {
  const [hazardClassification, setHazardClassification] = useState("Combustible dust in accordance with 29 CFR 1910.1200");
  const [signalWord, setSignalWord] = useState("Warning");
  const [hazardStatements, setHazardStatements] = useState("May form combustible dust concentrations in air.");
  const [precautionaryStatements, setPrecautionaryStatements] = useState("P210 Keep away from heat, hot surfaces, sparks, open flames and other ignition sources. No smoking.\nP243 Take precautionary measures against static discharge.\nP233 Keep container tightly closed.");

  return (
    <Stack tokens={{ childrenGap: 3 }}>
      <SectionHeader>
        <SectionIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/f4de6a32e7c5c824224545ca24ff74a7e0dc3a4d20856b27b6abb547f0aec8f9?placeholderIfAbsent=true&apiKey=ddd3d38defa045c9975cb00e389f58fa" alt="Icon" />
        <SectionTitle>HAZARD IDENTIFICATION</SectionTitle>
      </SectionHeader>
      <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { width: '100%' } }}>
        <LabeledInput label="Hazard Classification" value={hazardClassification} onChange={setHazardClassification} />
        <LabeledInput label="Signal Word" value={signalWord} onChange={setSignalWord} />
      </Stack>
      <LabeledInput label="Hazard Statements" value={hazardStatements} onChange={setHazardStatements} />
      <LabeledInput label="Precautionary Statements" value={precautionaryStatements} onChange={setPrecautionaryStatements} />
    </Stack>
  );
};

const LabeledInput = ({ label, value, onChange }: { label: string; value: string; onChange: (value: string) => void }) => (
  <InputContainer>
    <Label>{label}</Label>
    <TextField
      styles={textFieldStyles}
      value={value}
      onChange={(e, newValue) => onChange(newValue || '')} // Handle the change event
    />
  </InputContainer>
);

const textFieldStyles = {
  fieldGroup: { borderRadius: 8, border: '1px solid #4952a3', height: '40px' },
  field: { padding: '5px 10px', fontSize: 14 },
};

const SectionHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  color: #4952a3;
`;

const SectionIcon = styled.img`
  width: 18px;
  height: 18px;
`;

const SectionTitle = styled.h2`
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
`;

const InputContainer = styled.div`
  flex-grow: 1; /* Allow the input container to take the remaining width */
  width: 100%; /* Ensure full width */
`;

export default HazardIdentification;
