import React, { useState } from 'react';
import styled from 'styled-components';

// Assume these components are defined elsewhere
import FormField from './FormField';
import DatePicker from './DatePicker';
import Dropdown from './Dropdown';
import StatusIndicator from './StatusIndicator';

interface IntegrationPage{
    processSpeed: string,
    dataAccuracy: string,
    qualityCheck: string,
    notification: string
}
const IntegrationPage: React.FC<IntegrationPage> = ({processSpeed, dataAccuracy, qualityCheck, notification}) => {
  return (
    <FormWrapper> 
      <FormRow>
      <StatusIndicator label="Upload to ERP" status="Successful" />     
      <FormField 
        label="Process Speed" 
        placeholder="Process Speed" 
        value={processSpeed}  
      />
      </FormRow>
        
      <FormField 
        label="Data Accuracy" 
        placeholder="Enter the details" 
        value={dataAccuracy} 
      />
      <FormField 
        label="Quality Check" 
        placeholder="Browser" 
        value={qualityCheck} 
      />  
      <FormField 
        label="Notification" 
        placeholder="Browser" 
        value={notification} 
      />     
    </FormWrapper>
  );
};

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px; // Space between fields
  margin: 20px;
`;

const FormRow = styled.div`
  display: flex;
  width: 100%;
  margin-top: 0px;
  justify-content: flex-end; /* Aligns items to the right */
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center; /* Centers items in column mode */
  }
`;

export default IntegrationPage;
