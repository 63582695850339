/* eslint-disable react-hooks/exhaustive-deps */
import { DetailsList, DetailsListLayoutMode, IconButton, PrimaryButton, SelectionMode, Stack } from "@fluentui/react";
import { Button, Dialog, DialogBody, DialogSurface, InputOnChangeData, SearchBox, Switch, Text, Title2 } from "@fluentui/react-components";
import { Add16Filled } from "@fluentui/react-icons";
import React, { useEffect, useState } from "react";
import { UserForm } from "./UserForm";
import { User, UserPageData } from "./types";
import { useDeleteUserMutation, useGetAllUsersMutation } from "../../store/api/userApi";
import InlineLoader from "../common/InlineLoader";
import { CustomMessage } from "../common/CustomMessage";

interface UserMgmtProps {
  toastError(data: string, intent: string): void;
}

export const UserManagement: React.FC<UserMgmtProps> = (props) => {
  const offset = 2;
  const [ isEdit, setIsEdit ] = useState<boolean>(false);
  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ isError, setIsError ] = useState<boolean>(false);
  const [ isDialogOpen, setIsDialogOpen ] = useState<boolean>(false);
  const [ searchText, setSearchText ] = useState<string>('');
  const [ editRecord, setEditRecord ] = useState<User | null>(null);
  const [ sortedColumn, setSortedColumn ] = useState<{ key: string; isSortedDescending: boolean }>({
    key: 'id',
    isSortedDescending: false,
  });
  const [ findUsers, findUsersResponse ] = useGetAllUsersMutation();
  const [ userData, setUserData ] = useState<UserPageData | any>(null);
  const [ deleteUser, deleteUserResponse ] = useDeleteUserMutation();

  const handlePageChange = (newPage: number) => {
    setIsLoading(true);
    searchUsers(newPage, offset, searchText, sortedColumn.key, sortedColumn.isSortedDescending)
  };

  const onColumnClick = (event: React.MouseEvent<HTMLElement>, column: any) => {
    const { key } = column as { key: keyof User };
    const isSortedDescending = key === sortedColumn.key ? !sortedColumn.isSortedDescending : true;
    setSortedColumn({ key, isSortedDescending });
    searchUsers(userData.page, offset, searchText, key, isSortedDescending);
  };

  const columns = [
    {
        key: 'id',
        name: 'Id',
        fieldName: 'id',
        minWidth: 30,
        maxWidth: 50,
        isResizable: true,
        isSorted: sortedColumn.key === 'id',
        isSortedDescending: sortedColumn.isSortedDescending,
        onColumnClick: onColumnClick,
    },
    {
      key: 'firstName',
      name: 'First Name',
      fieldName: 'firstName',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isSorted: sortedColumn.key === 'firstName',
      isSortedDescending: sortedColumn.isSortedDescending,
      onColumnClick: onColumnClick,
    },
    {
      key: 'lastName',
      name: 'Last Name',
      fieldName: 'lastName',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isSorted: sortedColumn.key === 'lastName',
      isSortedDescending: sortedColumn.isSortedDescending,
      onColumnClick: onColumnClick,
    },
    {
      key: 'emailId',
      name: 'Email Id',
      fieldName: 'emailId',
      minWidth: 100,
      maxWidth: 150,
      isResizable: true,
      isSorted: sortedColumn.key === 'emailId',
      isSortedDescending: sortedColumn.isSortedDescending,
      onColumnClick: onColumnClick,
    },
    {
      key: 'username',
      name: 'Username',
      fieldName: 'username',
      minWidth: 150,
      isResizable: true,
      isSorted: sortedColumn.key === 'username',
      isSortedDescending: sortedColumn.isSortedDescending,
      onColumnClick: onColumnClick,
    },
    {
      key: 'enabled',
      name: 'Active',
      minWidth: 100,
      isResizable: false,
      onRender: (item: any) => (
        <Switch checked={item.enabled}/>
      )
    },
    {
        key: 'actions',
        name: 'Actions',
        minWidth: 100,
        isResizable: true,
        onRender: (item: any) => (
            <div>
                <IconButton iconProps={{ iconName: 'Delete' }} title="Remove" onClick={() => deleteUser(item.id)} />
                <IconButton iconProps={{ iconName: 'Edit' }} title="Edit" onClick={() => {
                  setEditRecord(item);
                  setIsEdit(true);
                  setIsDialogOpen(true);
                }}/>
            </div>
        ),
    },
  ];

  const onSearchChange = (event: any, data: InputOnChangeData) => {
    setSearchText(data.value);
    if(data.value.length > 2) {
      searchUsers(userData.page, offset, data.value,sortedColumn.key, sortedColumn.isSortedDescending);
    }
    if(data.value.length === 0) {
      searchUsers(userData.page, offset, null,sortedColumn.key, sortedColumn.isSortedDescending);
    }
  }

  const searchUsers = (page: number, offset: number, searchParam: string | null, sortColumn: string, isSortedDescending: boolean) => {
    const payload = {
      page,
      offset,
      searchParam,
      sortColumn,
      sortDir: isSortedDescending ? "ASC" : "DESC"
    }
    findUsers(payload);
  }

  useEffect(() => {
    searchUsers(0,offset,null,sortedColumn.key, sortedColumn.isSortedDescending);
  }, []);

  useEffect(() => {
    if(findUsersResponse.isSuccess) {
      setUserData(findUsersResponse.data.data);
    }
    if(findUsersResponse.isError) {
      props.toastError(findUsersResponse.error?.message, "error");
      setIsLoading(false);
      setIsError(true);
    }
  }, [findUsersResponse]);

  useEffect(() => {
    if(userData !== null) setIsLoading(false);
  }, [userData]);

  useEffect(() => {
    if(deleteUserResponse.isSuccess) {
      props.toastError("Record Deleted Successfully", "success");
      searchUsers(userData.page, offset, searchText, sortedColumn.key, sortedColumn.isSortedDescending);
    }
    if(findUsersResponse.isError) {
      props.toastError(deleteUserResponse.error?.message, "error");
    }
  }, [findUsersResponse]);

  return(
    <>
      <Stack horizontal tokens={{ childrenGap: 15 }} styles={{ root: { margin: '10px 20px', justifyContent:"space-between" } }}>
        <Title2 style={{ color: '#4f52b2'}}>Users</Title2>
        <SearchBox style={{ width: '100%'}} placeholder="Search" onChange={onSearchChange}/>
        <Button icon={<Add16Filled />} iconPosition="after" size="medium" appearance="primary"
          onClick={() => {setIsEdit(false); setIsDialogOpen(true);}}
        >
          ADD
        </Button>
      </Stack>
      {isLoading ? <InlineLoader /> :
        isError ? <CustomMessage intent="error" message="There seems to be an issue, Please try again Later"/> :
        <>
          <DetailsList
            items={userData.data}
            columns={columns}
            selectionMode={SelectionMode.none}
            setKey="set"
            layoutMode={DetailsListLayoutMode.justified}
            isHeaderVisible={true}
          />
          <Stack horizontal tokens={{ childrenGap: 10 }} styles={{ root: { margin: '20px', justifyContent: 'center', alignItems: 'center' } }}>
            <PrimaryButton
              text="Previous"
              disabled={userData.page === 0}
              onClick={() => handlePageChange(userData.page - 1)}
            />
            <Text>{`Page ${userData.page+1} of ${userData.totalPage}`}</Text>
            <PrimaryButton
              text="Next"
              disabled={userData.page === (userData.totalPage - 1)}
              onClick={() => handlePageChange(userData.page + 1)}
            />
          </Stack>
        </>
      }
      <Dialog open={isDialogOpen}>
        <DialogSurface style={{ padding : '2rem 3rem'}}>
          <DialogBody style={{ display: 'flex', flexDirection: 'column'}}>
            <UserForm isEdit={isEdit} editRecord={editRecord}
              closeDialog={(getData: boolean) => {
                setEditRecord(null);
                setIsDialogOpen(false);
                if(getData)
                  searchUsers(0, offset, searchText, sortedColumn.key, sortedColumn.isSortedDescending);
              }}
              toastError={props.toastError}
            />
          </DialogBody>
        </DialogSurface>
      </Dialog>
    </>
  );
}