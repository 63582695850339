// LoginPage.tsx
import React, { useEffect } from 'react';
import styled from 'styled-components';
import LoginForm from './LoginForm';
import WelcomeSection from './WelcomeSection';
import { useNavigate } from 'react-router-dom';
import { useLoginUserMutation } from '../../store/api/userApi';
import { useAppDispatch } from '../../store/hooks';
import { setAuthData, setIsAuthenticated, setUserData } from '../../store/state/userSlice';

interface LoginPageProps {
  toastError(data: string, intent: string): void;
}

const LoginPage: React.FC<LoginPageProps> = ({toastError}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [ loginUser, loginUserResponse ] = useLoginUserMutation();

  const handleLogin = (username: string, password: string) => {
    const payload = { username, password};
    loginUser(payload);
  };

  useEffect(() => {
    if(loginUserResponse.isSuccess) {
      const res = loginUserResponse.data.data;
      if(res.status === 'FAILED') {
        toastError(res.message, "error");
      }
      if(res.status === 'SUCCESS') {
        const userData = res.data[0].userData;
        const authData = res.data[0].authData;
        console.log("user ", userData);  console.log("authData ", authData);
        dispatch(setIsAuthenticated(true));
        dispatch(setUserData(userData));
        dispatch(setAuthData(authData));
        navigate('/document');
      }
    }
    if(loginUserResponse.isError) {
      toastError(loginUserResponse.error?.message, "error");
    }
  }, [loginUserResponse])

  const handleGoogleSuccess = async (token: string) => {
    try {
      const response = await fetch('YOUR_GOOGLE_API_ENDPOINT', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (!response.ok) {
        throw new Error('Google Sign-In failed. Please try again.');
      }

      const data = await response.json();
      localStorage.setItem('token', data.token);
      navigate('/document');
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message);
      } else {
        alert('An unexpected error occurred.');
      }
    }
  };

  return (
    <SplitScreen>
      <LeftPane>
        <WelcomeSection />
      </LeftPane>
      <RightPane>
        <LoginForm onLogin={handleLogin} onGoogleSuccess={handleGoogleSuccess} />
      </RightPane>
    </SplitScreen>
  );
};

const SplitScreen = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column; 
  }
`;

const LeftPane = styled.div`
     background-color: #4952A3;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 60%;
    margin-top: -20px;
    margin-left: -20px;

  @media (max-width: 768px) {
    width: 100%;
    height: auto; 
  }
`;

const RightPane = styled.div`
  background-color: #ffffff;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;

  @media (max-width: 768px) {
    width: 100%;
    height: auto; 
  }
`;

export default LoginPage;
